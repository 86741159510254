import { ProDescriptions } from '@ant-design/pro-components';
import { Input, Tooltip } from 'antd';
import { ReactNode, useRef } from 'react';

export default (props:{
    title:ReactNode
    datasource:any,
    columns:{title:string, key:string, dataIndex:string}[],
    onSave:(
        /** 行 id，一般是唯一id */
        key: any, 
        /** 当前修改的行的值，只有 form 在内的会被设置 */
        record: any, 
        /** 原始值，可以用于判断是否修改 */
        originRow: any, 
        /** 新建一行的配置，一般无用 */
        newLineConfig?: any) => Promise<any | void>
   
}) => {
  const actionRef = useRef();
  return (
    <ProDescriptions
      actionRef={actionRef}
      // bordered
    
      title={props.title}
      dataSource={props.datasource}
      editable={{
        onSave:props.onSave
      }}
      columns={props.columns}
    >
   
    </ProDescriptions>
  );
};