import axios, { AxiosError } from 'axios';

axios.defaults.baseURL = "/"
const apiClient = axios.create({
    baseURL: process.env.NODE_ENV.toLocaleLowerCase() == "development" ? "http://localhost:8080/api" : process.env.REACT_APP_STAGING == "jellykite" ? "https://verifier.jellykite.com/api" : "https://verifier.jellykite.com/api",
    // other settings...
});


apiClient.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem('userToken');
        console.log("token",token)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export function handleError(e: any): { 
    success: false
    error: { code: number; message: string } 
} {
    console.error(e)
    if (e && axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        if (axiosError.response) {
            if (axiosError.response.status === 400) {
                return axiosError.response.data as { success: false; error: { code: number; message: string } };
            } else if (axiosError.response.status === 403) {
                localStorage.clear();
                window.location.href = '/';
       
                return axiosError.response.data as { success: false;  error: { code: number; message: string } };
            } else if (axiosError.response.status === 401) {
                localStorage.clear();
                window.location.href = '/';
                return axiosError.response.data as { success: false;  error: { code: number; message: string } };
            } else {
                return { success: false,  error: { code: axiosError.response.status, message: e.message } };
            }
        }
    }
    return { success: false, error: { code: 0, message: "Unexpected Error" } };

}
export default apiClient;