import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useTranslation } from "react-i18next"

interface Props {
  onClick: () => void
}

const ProTableNewButton = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      key="proTableNewButton"
      icon={<PlusOutlined />}
      onClick={() => {
        props.onClick()
      }}
      type="primary"
    >
      {t("New")}
    </Button>
  )
}

export default ProTableNewButton
