import type { ProColumns } from "@ant-design/pro-components";
import {
  EditableProTable,
  ProCard,
  ProFormField,
  ProFormRadio,
  intlMap,
} from "@ant-design/pro-components";
import { ConfigProvider } from "antd";
import { t } from "i18next";
import React, { useState } from "react";

export default (props: {
  dataSource: any & { id: any }[];
  columns: ProColumns<any>[];
  onsave: (rowKey: any, data: any, row: any) => Promise<void>;
  onDelete: (id: any, row: any) => Promise<void>;
  onChange?: () => void;
}) => {
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<readonly any[]>([]);

  const columns: ProColumns<any>[] = [
    ...props.columns,
    {
      title: t("Actions"),
      valueType: "option",
      width: 200,
      render: (text, record, _, action) => [
        <a
          key="editable"
          onClick={() => {
            console.log(record);
            action?.startEditable?.(record.id);
          }}
        >
          {t("Edit")}
        </a>,
        <a
          key="delete"
          onClick={() => {
            props.onDelete(record.id, record);
          }}
        >
          {t("Delete")}
        </a>,
      ],
    },
  ];

  return (
    <>
      <ConfigProvider locale={intlMap["en-US"]}>
        <EditableProTable<any>
          rowKey="id"
          headerTitle=""
          scroll={{
            x: 960,
          }}
          recordCreatorProps={{
            creatorButtonText: t("Add New Row"),
            position: "bottom",
            record: () => ({ id: -1 }),
          }}
          loading={false}
          columns={columns}
          value={props.dataSource}
          onChange={props.onChange}
          editable={{
            deletePopconfirmMessage: t("Are you sure to delete this record?"),
            onlyOneLineEditorAlertMessage: t("Only One Line Can Be Edited"),
            onlyAddOneLineAlertMessage: t("Only Add One Line"),
            saveText: t("Save"),
            cancelText: t("Cancel"),
            deleteText: t("Delete"),
            type: "multiple",
            onDelete: props.onDelete,
            editableKeys,
            onSave: props.onsave,
            onChange: setEditableRowKeys,
          }}
        />
      </ConfigProvider>
    </>
  );
};
