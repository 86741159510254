import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";

import {
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import { AntDProTable } from "../../components/antdProTable";
import { accountService } from "../../services/accountService";

import { useForm } from "antd/es/form/Form";
import { ImportHistory } from "../../model/importHistory";
import Dragger from "antd/es/upload/Dragger";
import { documentTypeServices } from "../../services/documentService";
import { arrayBufferToBase64 } from "../../tools/encoder";
import { DocumentDefinitionSchema } from "../../model/documentDefinition";
import Swal from "sweetalert2";
import moment from "moment";
//Start
export function AllDocumentListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [menuActivekey, setMenuActiveKey] = useState<string>("all-files");
  const [filterByStatus, setFilterByStatus] = useState<string>("");
  const [importForm] = useForm();
  const [uploadFile, setUploadFile] = useState<string>();
  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    DocumentDefinitionSchema[]
  >([]);
  const [summary, setDocumentSummary] = useState<{
    recordTotal: number;
    result: { count: number; _id: string }[];
  }>({ recordTotal: 0, result: [] });
  let tableRef = useRef<ActionType>();

  useEffect(() => {
    documentTypeServices.getAllDocumentTypeOptions().then((res) => {
      if (res.success == true) {
        setDocumentTypeOptions(res.result);
      }
    });
    reloadSummary();
  }, []);
  const reloadSummary = () => {
    documentTypeServices.getDocumentsSummary().then((res) => {
      if (res.success == true) {
        setDocumentSummary({
          recordTotal: res.recordTotal,
          result: res.result,
        });
      }
    });
  };
  const reload = () => {
    tableRef.current?.reload();
  };
  useEffect(() => {
    reload();
  }, [filterByStatus]);
  const columns: ProColumns<ImportHistory>[] = [
    {
        title: t("Document Reference"),
        dataIndex: "_id",
        hideInSearch: false,
        sorter: true
      },
    {
      title: t("Document Name"),
      dataIndex: "documentName",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Document Type"),
      dataIndex: "documentDefinitionValue",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        if (Array.isArray(text) && text.length > 0) {
          return text[0].documentTypeName;
        } else {
          return "";
        }
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Created Date"),
      dataIndex: "createDate",
      hideInSearch: true,
      defaultSortOrder:"descend",
      sorter: true,
      renderText: (text) => {
        return moment(new Date(text)).format("yyyy-MM-DD HH:mm:ss");
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            <Link to={`/dashboard/admin-document/${record._id}`}>
              <FolderViewOutlined />
            </Link>
           
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <AntDProTable<ImportHistory>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
          toolbar={{
            menu: {
              type: "tab",
              activeKey: menuActivekey,
              onChange: (activeKey) => {
                let key = activeKey?.toString() || "";

                switch (key) {
                  case "please-review":
                    setFilterByStatus("OCRCompleted");
                    break;
                  case "awaiting-approval":
                    setFilterByStatus("PendingForApproval");
                    break;
                  case "approved-files":
                    setFilterByStatus("Approved");
                    break;
                  case "rejected-files":
                    setFilterByStatus("Rejected");
                    break;
                  case "all-files":
                    setFilterByStatus("");
                    break;
                }
                setMenuActiveKey(key);
              },
              items: [
                {
                  key: "all-files",
                  label: (
                    <span>
                      {t("All Files")}{" "}
                      <Badge
                        count={summary.recordTotal}
                        showZero
                        color="geekblue"
                      />
                    </span>
                  ),
                },
                {
                  key: "please-review",
                  label: (
                    <span>
                      {t("Please review")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "OCRCompleted")
                            ?.count || 0
                        }
                        showZero
                        color="cyan"
                      />
                    </span>
                  ),
                },
                {
                  key: "awaiting-approval",
                  label: (
                    <span>
                      {t("Awaiting Approval")}{" "}
                      <Badge
                        count={
                          summary.result.find(
                            (p) => p._id == "PendingForApproval"
                          )?.count || 0
                        }
                        showZero
                        color="orange"
                      />
                    </span>
                  ),
                },
                {
                  key: "approved-files",
                  label: (
                    <span>
                      {t("Approved")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "Approved")
                            ?.count || 0
                        }
                        showZero
                        color="lime"
                      />
                    </span>
                  ),
                },
                {
                  key: "rejected-files",
                  label: (
                    <span>
                      {t("Rejected")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "Rejected")
                            ?.count || 0
                        }
                        showZero
                        color="magenta"
                      />
                    </span>
                  ),
                },
              ],
            },
            //actions: [
            // 
            //  <Button
            //    key="primary"
            //    type="primary"
            //    onClick={() => setOpenExportModal(true)}
            //  >
            //    <CloudDownloadOutlined />
            //    {t("Export")}
            //</Button>,
            //],
          }}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            if (filterByStatus.length > 0) {
              where.status = filterByStatus;
            }
            setLoading(true);
            documentTypeServices
              .getAllDocumentsDatatable({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>
  
    </>
  );
}
