import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";

import {
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MoreOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { AntDProTable } from "../../../components/antdProTable";
import { accountService } from "../../../services/accountService";

import { useForm } from "antd/es/form/Form";
import Swal from "sweetalert2";
import { User } from "../../../model/user";
import { documentTypeServices } from "../../../services/documentService";
import { DocumentDefinitionSchema } from "../../../model/documentDefinition";

//Start
export function DocumentTypesListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [menuActivekey, setMenuActiveKey] = useState<string>("application-all");
  const [filterByStatus, setFilterByStatus] = useState<number[]>([]);
  const [editAccount, setEditAccount] = useState<DocumentDefinitionSchema>();
  const [editAccountForm] = useForm();
  let tableRef = useRef<ActionType>();

  const reload = () => {
    tableRef.current?.reload();
  };
  useEffect(() => {
    reload();
  }, [filterByStatus]);
  const columns: ProColumns<DocumentDefinitionSchema>[] = [
    {
      title: t("Document Type Name"),
      dataIndex: "documentTypeName",
      sorter: true,
    },
    {
      title: t("Enable"),
      dataIndex: "enable",
      sorter: false,
      renderText(text, record, index, action) {
        return text ? t("Yes") : t("No");
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            <Button
              title={t("Edit")}
              type="link"
              onClick={() => {
                setEditAccount(record);
                editAccountForm.setFieldsValue(record);
              }}
            >
              <EditOutlined />
            </Button>

            <Button
              onClick={() => {
                Swal.fire({
                  title: "Do you want to delete the attachment?",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    documentTypeServices
                      .deleteDocumentType(record._id || "")
                      .then((res) => {
                        reload();
                      });
                  }
                });
              }}
              title={t("Delete Account")}
              type="link"
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <AntDProTable<DocumentDefinitionSchema>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          // columns={columns.filter((c) => c.key !== "remove")}
          rowKey="id"
          scroll={{ x: "80vw" }}
          // scroll={{ x: "max-content" }}
          toolbar={{
            actions: [
              <Button
                onClick={() => {
                  setEditAccount({
                    _id: "",
                    documentTypeName: "",
                    enable: false,
                    customerDefinition: [],
                    documentDefinition: [],
                    lineItemsDefinition: [],
                  });
                  editAccountForm.setFieldsValue({
                    _id: "",
                    documentTypeName: "",
                    enable: false,
                    customerDefinition: [],
                    documentDefinition: [],
                    lineItemsDefinition: [],
                  });
                }}
                key="primary"
                type="primary"
              >
                {t("New Document Type")}
              </Button>,
              <Button
                loading={loading}
                onClick={() => {
                  setLoading(true)
                  documentTypeServices.getAllDocumentTypeOptions(true,false).then((res) => {
                    setLoading(false)
                    if (res.success == true) {
                      const blob = new Blob([JSON.stringify(res.result)], { type: 'application/json' });

                      // Create URL from blob
                      const url = URL.createObjectURL(blob);

                      // Create link and click to trigger download
                      const link = document.createElement('a');
                      link.download = "documentTypeOptions.json";
                      link.href = url;
                      link.click();
                      // Clean up URL
                      URL.revokeObjectURL(url);
                    }
                  }).catch((err) => {
                    setLoading(false)
                  })
                }}
                key="primary"
                type="primary"
              >
                {t("Export")}
              </Button>,
            ],
          }}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            if (filterByStatus.length > 0) {
              where.Status = filterByStatus;
            }
            setLoading(true);
            documentTypeServices
              .getAllDocumentTypeDatatable({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                setLoading(false);
                if (res.success == true) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>

      <Modal
        title={t("")}
        okText={t("Save")}
        cancelText={t("Cancel")}
        centered
        open={editAccount !== undefined}
        onOk={() => {
          editAccountForm.submit();
        }}
        onCancel={() => setEditAccount(undefined)}
        width={1000}
      >
        <Form
          name="editAccountForm"
          layout="vertical"
          form={editAccountForm}
          initialValues={editAccount}
          onFinish={(props: {
            documentTypeName: string;
            enable: boolean;
            customerDefinition: { columnName: string; description: string }[];
            documentDefinition: { columnName: string; description: string }[];
            lineItemsDefinition: { columnName: string; description: string }[];
          }) => {
            if (!props.customerDefinition) {
              props.customerDefinition = []
            }
            if (!props.documentDefinition) {
              props.documentDefinition = []
            }
            if (!props.lineItemsDefinition) {
              props.lineItemsDefinition = []
            }
            if (editAccount?._id == "") {
              documentTypeServices
                .addDocumentType(props)
                .then((d) => {
                  if (d.success == true) {
                    setEditAccount(undefined);
                    tableRef.current?.reload();
                  }
                })
                .catch((p) => { });
            } else {
              documentTypeServices
                .editDocumentType({ _id: editAccount?._id || "", ...props })
                .then((d) => {
                  if (d.success == true) {
                    setEditAccount(undefined);
                    tableRef.current?.reload();
                  }
                })
                .catch((p) => { });
            }
          }}
        >
          <Row gutter={16}>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Document Type Name")}
                name="documentTypeName"
                rules={[
                  {
                    required: true,
                    message: t("Please input the document type name"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Enable")} name="enable">
                <Radio.Group optionType="button">
                  <Radio value={true}>{t("Enable")}</Radio>
                  <Radio value={false}>{t("Disable")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={24} sm={24} xs={24}>
              <Collapse

                style={{ width: "100%" }}
                defaultActiveKey={["customer-definition", "document-definition", "line-item-definition"]}
                items={[
                  {
                    label: t("Customer Definition"),
                    key: "customer-definition",
                    children: (
                      <Row
                        style={{
                          width: "100%",
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Form.List name="customerDefinition">
                            {(fields, { add, remove, move }) => (
                              <div
                                style={{
                                  display: "flex",
                                  rowGap: 16,
                                  flexDirection: "column",
                                }}
                              >
                                {fields.map((field, index) => (
                                  <Card
                                    size="small"
                                    key={field.key}
                                    extra={
                                      <>
                                        <UpOutlined
                                          onClick={() => {
                                            move(index, Math.max(0, index - 1));
                                          }}
                                        />

                                        <DownOutlined
                                          onClick={() => {
                                            move(
                                              index,
                                              Math.min(fields.length, index + 1)
                                            );
                                          }}
                                        />
                                        <CloseOutlined
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </>
                                    }
                                  >
                                    <Form.Item
                                      label={t("Column Name")}
                                      name={[field.name, "columnName"]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label={t("Description")}
                                      name={[field.name, "description"]}
                                    >
                                      <Input.TextArea />
                                    </Form.Item>
                                  </Card>
                                ))}

                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  {t("+ Add Item")}
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: t("Document Definition"),
                    key: "document-definition",
                    children: (
                      <Row>
                        <Col md={24} sm={24} xs={24}>
                          <Form.List name="documentDefinition">
                            {(fields, { add, remove, move }) => (
                              <div
                                style={{
                                  display: "flex",
                                  rowGap: 16,
                                  flexDirection: "column",
                                }}
                              >
                                {fields.map((field, index) => (
                                  <Card
                                    size="small"
                                    key={field.key}
                                    extra={
                                      <>
                                        <UpOutlined
                                          onClick={() => {
                                            move(index, Math.max(0, index - 1));
                                          }}
                                        />

                                        <DownOutlined
                                          onClick={() => {
                                            move(
                                              index,
                                              Math.min(fields.length, index + 1)
                                            );
                                          }}
                                        />
                                        <CloseOutlined
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </>
                                    }
                                  >
                                    <Form.Item
                                      label={t("Column Name")}
                                      name={[field.name, "columnName"]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label={t("Description")}
                                      name={[field.name, "description"]}
                                    >
                                      <Input.TextArea />
                                    </Form.Item>
                                  </Card>
                                ))}

                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  {t("+ Add Item")}
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    label: t("Line Item Definition"),
                    key: "line-item-definition",
                    children: (
                      <Row>
                        <Col md={24} sm={24} xs={24}>
                          <Form.List name="lineItemsDefinition">
                            {(fields, { add, remove, move }) => (
                              <div
                                style={{
                                  display: "flex",
                                  rowGap: 16,
                                  flexDirection: "column",
                                }}
                              >
                                {fields.map((field, index) => (
                                  <Card
                                    size="small"
                                    key={field.key}
                                    extra={
                                      <>
                                        <UpOutlined
                                          onClick={() => {
                                            move(index, Math.max(0, index - 1));
                                          }}
                                        />

                                        <DownOutlined
                                          onClick={() => {
                                            move(
                                              index,
                                              Math.min(fields.length, index + 1)
                                            );
                                          }}
                                        />
                                        <CloseOutlined
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </>
                                    }
                                  >
                                    <Form.Item
                                      label={t("Column Name")}
                                      name={[field.name, "columnName"]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      label={t("Description")}
                                      name={[field.name, "description"]}
                                    >
                                      <Input.TextArea />
                                    </Form.Item>
                                  </Card>
                                ))}

                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                >
                                  {t("+ Add Item")}
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    ),
                  },
                ]}
              ></Collapse>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
