import { TranslationOutlined } from "@ant-design/icons"
import { Button, Dropdown, MenuProps } from "antd"
import { useTranslation } from "react-i18next"


const ChangeLanguageButton = (props:any) => {
  const { i18n } = useTranslation()
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng) // Change language Globally
     window.location.reload()
  }
  const langs: MenuProps["items"] = [
    {
      key: "1",
      label: <a onClick={() => changeLanguage("tc")}>中文（繁體）</a>,
    },
    {
      key: "2",
      label: <a onClick={() => changeLanguage("cn")}>中文（简体）</a>,
    },
    {
      key: "3",
      label: <a onClick={() => changeLanguage("en")}>English</a>,
    },
  ]

  return (
    <Dropdown menu={{ items: langs }} placement="bottomRight">
      <Button {...props}>
        {<TranslationOutlined  style={{ marginBottom: -6, fontSize: 20 }} />}
      </Button>
    </Dropdown>
  )
}

export default ChangeLanguageButton
