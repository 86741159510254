import { DeleteOutlined, SmileOutlined } from "@ant-design/icons"
import { Button } from "antd"
import { useTranslation } from "react-i18next"

interface Props {
  showDeleted: boolean
  onClick: () => void
}

const ProTableShowDeletedButton = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Button
      key="proTableViewDeletedButton"
      icon={props.showDeleted ? <SmileOutlined /> : <DeleteOutlined />}
      onClick={() => {
        props.onClick()
      }}
      type="dashed"
      danger={!props.showDeleted}
    >
      {props.showDeleted ? t("Hide Deleted") : t("Show Deleted")}
    </Button>
  )
}

export default ProTableShowDeletedButton
