import {
  ApiOutlined,
  AuditOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CrownFilled,
  FolderViewOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  SmileFilled,
  UserOutlined
} from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, theme } from "antd";

import { t } from "i18next";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ChangeLanguageButton from "../components/changeLanguage";
import { DocumentTypesListPage } from "../pages/admin/documents/documentTypesListPage";
import { AllSubscriptionListPage } from "../pages/admin/subscriptions/allSubscriptionList.page";
import { SubscriptionAdminPage } from "../pages/admin/subscriptions/subscriptionAdmin.page";
import { UserListPage } from "../pages/admin/users/userListPage";
import { AllDocumentListPage } from "../pages/manager/alldocumentList.page";
import { DocumentAdminPage } from "../pages/manager/documentAdmin.page";
import { DocumentApproveListPage } from "../pages/manager/documentApproveList.page";
import { DocumentContentPage } from "../pages/uploadDocuments/documentContent.page";
import { ImportHistoryListPage } from "../pages/uploadDocuments/uploadDocument.page";
import { CompanyListPage } from "../pages/admin/company/companyListPage";

const { Header, Content, Sider } = Layout;
const items1: MenuProps["items"] = [
  {
    label: t("Admin Page"),
    key: "admin-page",
  },
];

export default () => {
  const [role, setRole] = useState<string>("user");
  const [rootCompany, setRootCompany] = useState<boolean>(false);
  const navigation = useNavigate();
  useEffect(() => {
    try {
      let token = localStorage.getItem("userToken");
      const user = jwtDecode<{ email: string; role: string,isRootCompany:boolean }>(token || "");
      setRole(user?.role);
      setRootCompany(user?.isRootCompany);
    } catch (err) {}
  }, []);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(true);
  return (
    <Layout>
      <Header style={{ display: "flex", alignItems: "center" }}>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={[
            {
              key: "menufold",
              label: collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                />
              ),
            },
            {
              key: "changelanguage",
              label: <ChangeLanguageButton style={{ background: "transparent", color: "#fff", border:"0" }} />,
            },
          ]}
          style={{ flex: 1, minWidth: 0, backgroundColor: "transparent" }}
        />
      </Header>
      <Layout style={{ minHeight: "calc(100vh - 64px)" }}>
        <Sider width={200} collapsed={collapsed} style={{ background: colorBgContainer }}>
          <Menu
           
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
            items={
              [
                {
                  label: t("Welcome"),
                  key: "welcome-menu",
                  icon: <SmileFilled />,
                  children: [
                    {
                      label: (
                        <Link to={"/dashboard/upload-doucuments"}>
                          {t("Upload Documents")}
                        </Link>
                      ),
                      key: "upload-doucuments",
                      icon: <CloudUploadOutlined />,
                    },
                  ],
                },
                role == "manager" || role == "admin"
                  ? {
                      label: t("Manager"),
                      key: "manager-menu",
                      icon: <CrownFilled />,
                      children: [
                        {
                          label: (
                            <Link to={"/dashboard/approve-doucuments"}>
                              {t("Approve Documents")}
                            </Link>
                          ),
                          key: "approve-doucuments",
                          icon: <AuditOutlined />,
                        },
                        {
                          label: (
                            <Link to={"/dashboard/all-doucuments"}>
                              {t("All Documents")}
                            </Link>
                          ),
                          key: "all-doucuments",
                          icon: <FolderViewOutlined />,
                        },
                      ],
                    }
                  : undefined,
                role == "admin"
                  ? {
                      label: t("Admin Page"),
                      key: "admin-menu",
                      icon: <SettingOutlined />,
                      children: [
                        rootCompany?{
                          key: "/admin/company",
                          label: (
                            <Link to={"/dashboard/admin/companies"}>
                              {t("Company Management")}
                            </Link>
                          ),
                          icon: <ClusterOutlined />,
                        }:null,
                        {
                          key: "/admin/users",
                          label: (
                            <Link to={"/dashboard/admin/users"}>
                              {t("User Management")}
                            </Link>
                          ),
                          icon: <UserOutlined />,
                        },
                        {
                          key: "/admin/documentTypes",
                          label: (
                            <Link to={"/dashboard/admin/documentTypes"}>
                              {t("Document Type Management")}
                            </Link>
                          ),
                          icon: <AuditOutlined />,
                        },
                        {
                          key: "/admin/apis",
                          label: (
                            <Link to={"/dashboard/admin/subscriptions"}>
                              {t("API Management")}
                            </Link>
                          ),
                          icon: <ApiOutlined /> ,
                        },
                      ].filter(p=>p!=null),
                    }
                  : undefined,

                {
                  type: 'item',
                  key: "/admin/logout",
                  onClick: () => {
                    localStorage.clear();
                        navigation("/");
                  },
                  label: (
                    <Button
                      style={{ color: "#222", textAlign: "left", padding: 0 }}
                      type="link"
                    >
                      {t("Logout")}
                    </Button>
                  ),
                  icon: <LogoutOutlined />,
                },
              ].filter((p) => p != undefined) as {
                label: string;
                key: string;
              }[]
            }
          />
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Routes>
            <Route path="/home" Component={ImportHistoryListPage} />
            <Route path="/upload-doucuments" Component={ImportHistoryListPage} />
            <Route path="/approve-doucuments" Component={DocumentApproveListPage} />
            <Route path="/all-doucuments" Component={AllDocumentListPage} />
            <Route path="/admin-document/:id" Component={DocumentAdminPage} />
            <Route path="/documents/:id" Component={DocumentContentPage} />
            <Route path="/admin/users" Component={UserListPage} />
            <Route path="/admin/companies" Component={CompanyListPage} />
            <Route path="/admin/subscriptions" Component={AllSubscriptionListPage} />
            <Route path="/admin/documentTypes" Component={DocumentTypesListPage} />
            <Route path="/admin/subscription/:id" Component={SubscriptionAdminPage} />
            <Route path="*" element={<Navigate to="/dashboard/home" replace />} />
          </Routes>
        </Layout>
      </Layout>
    </Layout>
  );
};
