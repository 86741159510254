import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";

import { EditOutlined, FolderViewOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import { AntDProTable } from "../../../components/antdProTable";
import { useForm } from "antd/es/form/Form";

import { ImportHistory } from "../../../model/importHistory";
import { documentTypeServices } from "../../../services/documentService";
import { subscriptionService } from "../../../services/subscriptionService";
import { SubscriptionSchema } from "../../../model/subscriptionSchema.schema";
import dayjs from "dayjs";
import { accountService } from "../../../services/accountService";
import { jwtDecode } from "jwt-decode";

//Start
export function AllSubscriptionListPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [createFormData, setcreateFormData] = useState<SubscriptionSchema>();
  const [updateFormData, setUpdateFormData] = useState<SubscriptionSchema>();
  const [userOptions, setUserOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [subscriptionCalculationMethod, setSubscriptionCalculationMethod] =
    useState<string>();
  const [createForm] = useForm();
  const [updateForm] = useForm();
  let tableRef = useRef<ActionType>();

  const [rootCompany, setRootCompany] = useState<boolean>(false);
  useEffect(() => {
    try {
      let token = localStorage.getItem("userToken");
      const user = jwtDecode<{ email: string; role: string, isRootCompany: boolean }>(token || "");
      setRootCompany(user?.isRootCompany);
    } catch (err) { }
  }, []);
  useEffect(() => {
    console.log("list-all-users")
    accountService.getAllUser({ params: { current: 1 }, take: 50 }).then((d) => {
      console.log(d)
      if (d.success) {
        setUserOptions(
          d.result.map((item) => {
            return {
              value: item._id,
              label: item.email,
            };
          })
        );
      }
    });
  }, []);

  const columns: ProColumns<SubscriptionSchema>[] = [
    {
      title: t("Subscription ID"),
      dataIndex: "_id",
      defaultSortOrder: "descend",
      hideInSearch: true,
      hideInTable: false
    },
    {
      title: t("Name"),
      dataIndex: "subscriptionName",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Description"),
      dataIndex: "subscriptionDescription",
      hideInSearch: true,
      sorter: false,

    },
    {
      title: t("Status"),
      dataIndex: "subscriptionStatus",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Client"),
      dataIndex: "Client",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        if (Array.isArray(text) && text.length > 0) {
          return text[0].email
        }
        return;
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            {rootCompany && <Button
              title={t("Edit")}
              type="link"
              onClick={() => {
                setUpdateFormData(record);
                setSubscriptionCalculationMethod(record.subscriptionCalculationMethod);
                updateForm.setFieldsValue({
                  ...record,
                  emailWhiteList: record.emailWhiteList ?? [],
                  subscriptionPeriod: [dayjs(record.subscriptionBeginDate), dayjs(record.subscriptionExpireDate)]
                });
              }}
            >
              <EditOutlined />
            </Button>}
            <Link to={`/dashboard/admin/subscription/${record._id}`}>
              <FolderViewOutlined />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <AntDProTable<SubscriptionSchema>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
          toolbar={{
            actions: rootCompany ? [
              <Button
                onClick={() => {
                  setcreateFormData({
                    _id: "",
                    subscriptionName: "",
                    subscriptionStatus: "",
                    subscriptionCalculationMethod: "",
                    subscriptionDescription: "",
                    subscriptionToken: 0,
                    ownerId: "",
                    emailWhiteList: [],
                  });
                  createForm.setFieldsValue({
                    _id: "",
                    subscriptionName: "",
                    subscriptionStatus: "",
                    subscriptionCalculationMethod: "",
                    subscriptionDescription: "",
                    subscriptionToken: 0,
                    ownerId: "",
                    emailWhiteList: [],
                  });
                }}
                key="primary"
                type="primary"
              >
                {t("New Subscription")}
              </Button>,
            ] : [],
          }}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            setLoading(true);
            subscriptionService
              .getAllSubscription({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result.map(p => {

                      return { ...p, emailWhiteList: p.emailWhiteList ?? [] }
                    }),
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>

      <Modal
        title={t("")}
        okText={t("Save")}
        cancelText={t("Cancel")}
        centered
        open={createFormData !== undefined}
        onOk={() => {
          createForm.submit();
        }}
        onCancel={() => setcreateFormData(undefined)}
        width={1000}
      >
        <Form
          name="createForm"
          layout="vertical"
          form={createForm}
          initialValues={createFormData}
          onValuesChange={({
            subscriptionCalculationMethod,
          }: {
            subscriptionCalculationMethod: "Token" | "Time";
          }) => {
            if (subscriptionCalculationMethod) {
              setSubscriptionCalculationMethod(subscriptionCalculationMethod);
            }
          }}
          onFinish={(
            props: SubscriptionSchema & { subscriptionPeriod: dayjs.Dayjs[] }
          ) => {
            let p: {
              ownerId: string;
              emailWhiteList: string[];
              subscriptionName: string;
              subscriptionDescription: string;
              subscriptionStatus: string;

              subscriptionBeginDate: number;
              subscriptionExpireDate: number;
              subscriptionToken: number;
              subscriptionCalculationMethod: string;
            } = {
              ...props,
              subscriptionBeginDate: 0,
              subscriptionToken: 0,
              subscriptionExpireDate: 0,
            };
            if (props.subscriptionCalculationMethod == "Token") {
              p.subscriptionToken = props.subscriptionToken || 0;
              p.subscriptionBeginDate = 0;
              p.subscriptionExpireDate = 0;
            } else {
              //Time
              props.subscriptionToken = 0;
              if (props.subscriptionPeriod.length > 0) {
                p.subscriptionBeginDate = props.subscriptionPeriod[0]
                  .toDate()
                  .getTime();
                p.subscriptionExpireDate = props.subscriptionPeriod[1]
                  .toDate()
                  .getTime();
              }
            }

            subscriptionService
              .addtSubscription(p)
              .then((d) => {
                if (d.success == true) {
                  setcreateFormData(undefined);
                  tableRef.current?.reload();
                }
              })
              .catch((p) => { });
          }}
        >
          <Row gutter={16}>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Owner")} name="ownerId">
                <Select
                  showSearch
                  onSearch={(value) => {
                    accountService
                      .getAllUser({ email: value, params: { current: 1 }, take: 50 })
                      .then((d) => {
                        if (d.success) {
                          setUserOptions(
                            d.result.map((item) => {
                              return {
                                value: item._id,
                                label: item.email,
                              };
                            })
                          );
                        }
                      });
                  }}
                  options={userOptions}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("EmailWhiteList")} name="emailWhiteList">
                <Select
                  mode="tags"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Name")} name="subscriptionName">
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Description")}
                name="subscriptionDescription"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Status")} name="subscriptionStatus">
                <Radio.Group optionType="button">
                  <Radio value={"Enable"}>{t("Enable")}</Radio>
                  <Radio value={"Disable"}>{t("Disable")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Calculation Method")}
                name="subscriptionCalculationMethod"
              >
                <Radio.Group optionType="button">
                  <Radio value={"Time"}>{t("Time")}</Radio>
                  <Radio value={"Token"}>{t("Token")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Token")} name="subscriptionToken">
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={subscriptionCalculationMethod != "Token"}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={12} xs={24}>
              <Form.Item label={t("Date Period")} name="subscriptionPeriod">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  disabled={subscriptionCalculationMethod != "Time"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t("")}
        okText={t("Save")}
        cancelText={t("Cancel")}
        centered
        open={updateFormData !== undefined}
        onOk={() => {
          updateForm.submit();
        }}
        onCancel={() => setUpdateFormData(undefined)}
        width={1000}
      >
        <Form
          name="updateForm"
          layout="vertical"
          form={updateForm}
          initialValues={updateFormData}
          onValuesChange={({
            subscriptionCalculationMethod,
          }: {
            subscriptionCalculationMethod: "Token" | "Time";
          }) => {
            if (subscriptionCalculationMethod) {
              setSubscriptionCalculationMethod(subscriptionCalculationMethod);
            }
          }}
          onFinish={(
            props: SubscriptionSchema & { subscriptionPeriod: dayjs.Dayjs[] }
          ) => {
            let p: {
              _id: string,
              ownerId: string;
              emailWhiteList: string[];
              subscriptionName: string;
              subscriptionDescription: string;
              subscriptionStatus: string;

              subscriptionBeginDate: number;
              subscriptionExpireDate: number;
              subscriptionToken: number;
              subscriptionCalculationMethod: string;
            } = {
              ...props,
              _id: updateFormData?._id || "",
              subscriptionBeginDate: 0,
              subscriptionToken: 0,
              subscriptionExpireDate: 0,
            };
            if (props.subscriptionCalculationMethod == "Token") {
              p.subscriptionToken = props.subscriptionToken || 0;
              p.subscriptionBeginDate = 0;
              p.subscriptionExpireDate = 0;
            } else {
              //Time
              props.subscriptionToken = 0;
              if (props.subscriptionPeriod.length > 0) {
                p.subscriptionBeginDate = props.subscriptionPeriod[0]
                  .toDate()
                  .getTime();
                p.subscriptionExpireDate = props.subscriptionPeriod[1]
                  .toDate()
                  .getTime();
              }
            }

            subscriptionService
              .editSubscription(p)
              .then((d) => {
                if (d.success == true) {
                  setUpdateFormData(undefined);
                  tableRef.current?.reload();
                }
              })
              .catch((p) => { });
          }}
        >
          <Row gutter={16}>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Owner")} name="ownerId">
                <Select
                  disabled
                  options={[{ value: updateFormData?.ownerId, label: updateFormData?.Client ? updateFormData?.Client[0]?.email : "" }]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("EmailWhiteList")} name="emailWhiteList">
                <Select
                  mode="tags"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Name")} name="subscriptionName">
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Description")}
                name="subscriptionDescription"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Status")} name="subscriptionStatus">
                <Radio.Group optionType="button">
                  <Radio value={"Enable"}>{t("Enable")}</Radio>
                  <Radio value={"Disable"}>{t("Disable")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item
                label={t("Calculation Method")}
                name="subscriptionCalculationMethod"
              >
                <Radio.Group optionType="button">
                  <Radio value={"Time"}>{t("Time")}</Radio>
                  <Radio value={"Token"}>{t("Token")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
              <Form.Item label={t("Token")} name="subscriptionToken">
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={subscriptionCalculationMethod != "Token"}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} sm={12} xs={24}>
              <Form.Item label={t("Date Period")} name="subscriptionPeriod">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  disabled={subscriptionCalculationMethod != "Time"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
