import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Empty,
  Modal,
  Row,
  Space,
  Table,
  Tabs,
  message
} from "antd";

import {
  AuditOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { AntDProTable } from "../../components/antdProTable";

import { useForm } from "antd/es/form/Form";
import { DocumentDefinitionSchema } from "../../model/documentDefinition";
import { DocumentSchema } from "../../model/documentSchema.schema";
import { ImportHistory } from "../../model/importHistory";
import { documentTypeServices } from "../../services/documentService";

export function DocumentApproveListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentSchema>();

  const [importForm] = useForm();

  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    DocumentDefinitionSchema[]
  >([]);

  const [activeTabKey1, setActiveTabKey1] = useState<string>("tab1");

  let tableRef = useRef<ActionType>();

  useEffect(() => {
    documentTypeServices.getAllDocumentTypeOptions().then((res) => {
      if (res.success == true) {
        setDocumentTypeOptions(res.result);
      }
    });
  }, []);

  const reload = () => {
    tableRef.current?.reload();
  };

  const columns: ProColumns<ImportHistory>[] = [
    {
      title: t("Document Reference"),
      dataIndex: "_id",
      hideInSearch: false,
      sorter: false,
    },
    {
      title: t("Document Name"),
      dataIndex: "documentName",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Document Type"),
      dataIndex: "documentDefinitionValue",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        if (Array.isArray(text) && text.length > 0) {
          return text[0].documentTypeName;
        } else {
          return "";
        }
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            <Button
              onClick={() => {
                setLoading(true);
                documentTypeServices
                  .getOneDetailDocument(record._id || "")
                  .then((res) => {
                    setLoading(false);
                    if (res.success == true) {
                      if (res.result.report && Array.isArray(res.result.report?.LineDetails)) {
                        res.result.report.LineDetails =
                          res.result.report.LineDetails.map(
                            (p: any, i: any) => {
                              return { id: i, ...p };
                            }
                          );
                      }
                      setDocument(res.result);
                    }
                  });
              }}
              title={t("Approve or reject Document")}
              type="link"
            >
              <AuditOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const handleCancel = () => {
    setDocument(undefined);
  };

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <AntDProTable<ImportHistory>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
          toolbar={{}}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            setLoading(true);
            documentTypeServices
              .getAllApprovalDocumentDatatable({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>
      <Modal
        width={1500}
        okText={t("Save")}
        cancelText={t("Cancel")}
        open={document !== undefined}
        onOk={() => {
          importForm.submit();
        }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true);
              documentTypeServices
                .approveDocument(document?._id ?? "")
                .then((res) => {
                  setLoading(false);
                  if (res.success == true) {
                    message.success(t("Document approve successfully"));
                    setDocument(undefined);
                    reload();
                  }
                });
            }}
          >
            {t("Approve")}
          </Button>,
          <Button
            danger
            key="link"
            type="primary"
            loading={loading}
            onClick={() => {
              setLoading(true);
              documentTypeServices
                .rejectDocument(document?._id ?? "")
                .then((res) => {
                  setLoading(false);
                  if (res.success == true) {
                    message.success(t("Document rejected successfully"));
                    setDocument(undefined);
                    reload();
                  }
                });
            }}
          >
            {t("Reject")}
          </Button>,
        ]}
        onCancel={() => {
          setDocument(undefined);
        }}
      >
       
                  <Row gutter={16}>
                    <Col md={12} sm={24}>
                      {document?.pages && document?.pages.length > 0 ? (
                        <Tabs
                          defaultActiveKey="1"
                          tabPosition={"top"}
                          items={document?.pages.map((p: string, i: number) => {
                            return {
                              key: "document-page-" + i,
                              label: `P.${i + 1}`,
                              children: (
                                <>
                                  <Space
                                    style={{
                                      width: "100%",
                                      overflow: "auto",
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={p}
                                      style={{
                                        width: "100%",
                                        maxWidth: 1000,
                                        height: "auto",
                                      }}
                                    />
                                  </Space>
                                </>
                              ),
                            };
                          })}
                        />
                      ) : (
                        <Empty description={false} />
                      )}
                    </Col>

                    {document?.report ? (
                      <Col md={12} sm={24}>
                        <Descriptions
                          title={t("Customer Info")}
                          items={Object.keys(
                            document.report.CustomerDetail
                          ).map((p, i) => {
                            return {
                              key: `document-customer-info-${i}`,
                              label: p,
                              children: document.report?.CustomerDetail[p],
                            };
                          })}
                        />

                        <Descriptions
                          title={t("Document Info")}
                          items={Object.keys(
                            document.report.DocumentDetail
                          ).map((p, i) => {
                            return {
                              key: `document-document-info-${i}`,
                              label: p,
                              children: document.report?.DocumentDetail[p],
                            };
                          })}
                        />

                        <span style={{ fontWeight: 600, fontSize: 16 }}>
                          {t("Line Details")}
                        </span>

                        <Table
                          dataSource={document.report.LineDetails}
                          columns={
                            document.usingSchema &&
                            document.usingSchema.lineItemsDefinition.map(
                              (p, i) => {
                                return {
                                  title: p.columnName,
                                  dataIndex: p.columnName,
                                  key: `document-line-info-${i}-${p.columnName}`,
                                };
                              }
                            )
                          }
                        ></Table>
                      </Col>
                    ) : undefined}
                  </Row>
              
        
      </Modal>
    </>
  );
}
