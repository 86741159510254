import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Badge, Button, Card, Col, Form, Input, Modal, Row, Select } from "antd"

import { DeleteOutlined, EditOutlined, KeyOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

import { AntDProTable } from "../../../components/antdProTable"
import { accountService } from "../../../services/accountService"

import { useForm } from "antd/es/form/Form"
import Swal from "sweetalert2"
import { User } from "../../../model/user"
import dayjs from "dayjs"
import { jwtDecode } from "jwt-decode"
//Start
export function UserListPage() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [changePasswordAccount, setChangePasswordAccount] = useState<User>()
    const [editAccount, setEditAccount] = useState<User>()
    const [changePasswordForm] = useForm();
    const [editAccountForm] = useForm();
    const [menuActivekey, setMenuActiveKey] = useState<string>("my-company-users");
    let tableRef = useRef<ActionType>()
    const [rootCompany, setRootCompany] = useState<boolean>(false);
    useEffect(() => {
      try {
        let token = localStorage.getItem("userToken");
        const user = jwtDecode<{ email: string; role: string, isRootCompany: boolean }>(token || "");
        setRootCompany(user?.isRootCompany);
      } catch (err) { }
    }, []);
    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [menuActivekey])
    const columns: ProColumns<User>[] = [
        {
            title: t("Email"),
            dataIndex: "email",
            sorter: true,
        },
        {
            title: t("Role"),
            dataIndex: "role",
            sorter: true,
            valueEnum: {
                "admin": { text: t("Admin") },
                "manager": { text: t("Manager") },
                "user": { text: t("User") },
            }
        },
        {
            title: t("Company Name"),
            dataIndex: "CompanyValue",
            sorter: false,
            hideInSearch: true,
            renderText(text: {name:string}[], record, index, action) {
                if (text && Array.isArray(text) && text.length > 0) {
                    return text[0].name
                }
            },
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <>
                        <Button
                            title={t("Change Password")}
                            type="link"
                            onClick={() => {
                                setChangePasswordAccount(record)
                                changePasswordForm.setFieldsValue({ password: "", confirmPassword: "" })
                            }}
                        >
                            <KeyOutlined />
                        </Button>
                        {!record.locked && <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to lock the user account?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Lock',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.lockUser(record._id, dayjs().add(7, 'day').toDate()).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Lock Account")}
                            type="link"
                        >
                            <UnlockOutlined />
                        </Button>}
                        {record.locked && <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to unlock the user account?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Lock',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.unlockUser(record._id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Unlock Account")}
                            type="link"
                        >
                            <LockOutlined />
                        </Button>}
                        <Button
                            title={t("Edit")}
                            type="link"
                            onClick={() => {
                                setEditAccount(record)
                                editAccountForm.setFieldsValue(record)
                            }}
                        >
                            <EditOutlined />
                        </Button>

                        <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to delete the attachment?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Delete',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.deleteUser(record._id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Delete Account")}
                            type="link"
                        >
                            <DeleteOutlined />
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Card style={{ marginTop: 20 }}>
                <AntDProTable<User>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                        tableRef.current = ref.current
                    }}
                    pageSizeOptions={[10, 20, 50, 100, 200]}
                    columns={columns}
                    // columns={columns.filter((c) => c.key !== "remove")}
                    rowKey="id"
                    scroll={{ x: '80vw' }}
                    // scroll={{ x: "max-content" }}
                    toolbar={{
                        menu: rootCompany?{
                            type: "tab",
                            activeKey: menuActivekey,
                            onChange: (activeKey) => {
                                let key = activeKey?.toString() || "";

                                switch (key) {
                                    case "all-company-users":
                                        // setFilterByStatus("OCRCompleted");
                                        break;
                                    case "my-company-users":
                                        // setFilterByStatus("");
                                        break;
                                }
                                setMenuActiveKey(key);
                            },
                            items: [
                                {
                                    key: "all-company-users",
                                    label: (
                                        <span>
                                            {t("All Company Users")}
                                        </span>
                                    ),
                                },
                                {
                                    key: "my-company-users",
                                    label: (
                                        <span>
                                            {t("My Company Users")}
                                        </span>
                                    ),
                                }
                            ],
                        }:undefined,
                        actions: [
                            <Button onClick={() => {
                                setEditAccount({ _id: "", email: "", role: "admin", locked: false })
                                editAccountForm.setFieldsValue({ _id: "", email: "", role: "admin", locked: false, password: "", confirmPassword: "" })
                            }} key="primary" type="primary">
                                {t("New Account")}
                            </Button>,
                        ],
                    }}
                    
                    request={(
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy,
                        order,
                        cb
                    ) => {

                        setLoading(true);
                        accountService.getAllUser({
                            params,
                            sort,
                            filter,
                            skip,
                            take,
                            where,
                            sortBy,
                            order,
                        }, menuActivekey == "all-company-users").then(res => {
                            setLoading(false);
                            if (res.success == true) {
                                cb({
                                    data: res.result,
                                    total: res.recordTotal,
                                    success: true,
                                    page: params.current,
                                })
                            } else {
                                cb({
                                    data: [],
                                    total: 0,
                                    success: false,
                                    page: params.current,
                                })
                            }
                        }).catch(err => {
                            setLoading(false);
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        })
                    }}
                />
            </Card>
            <Modal
                title={t("")}
                okText={t("Save")}
                cancelText={t("Cancel")}
                centered
                open={changePasswordAccount !== undefined}
                onOk={() => {
                    changePasswordForm.submit();
                }}
                onCancel={() => setChangePasswordAccount(undefined)}
                width={1000}
            >
                <Form name="changePasswordForm" layout="vertical" form={changePasswordForm} initialValues={changePasswordAccount} onFinish={(props: { password: string, confirmPassword: string }) => {

                    accountService.changeUserPassword({ _id: changePasswordAccount?._id || "", ...props }).then(d => {
                        if (d.success) {
                            setChangePasswordAccount(undefined)
                            tableRef.current?.reload()
                        }
                    }).catch(p => {

                    })
                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Password")}
                                name="password"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t("Confirm Password")}
                                name="confirmPassword"
                                rules={[
                                    { required: true, message: t("Please confirm your password!") },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                                        },
                                    })
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title={t("")}
                okText={t("Save")}
                cancelText={t("Cancel")}
                centered
                open={editAccount !== undefined}
                onOk={() => {
                    editAccountForm.submit();
                }}
                onCancel={() => setEditAccount(undefined)}
                width={1000}
            >
                <Form name="editAccountForm" layout="vertical" form={editAccountForm} initialValues={editAccount} onFinish={(props: { email: string, role: string }) => {
                    if (editAccount?._id == "") {
                        accountService.addtUser({ _id: editAccount?._id || "", ...props }).then(d => {
                            if (d.success == true) {
                                setEditAccount(undefined)
                                tableRef.current?.reload()
                            }
                        }).catch(p => {

                        })
                    } else {
                        accountService.editUser({ _id: editAccount?._id || "", ...props }).then(d => {
                            if (d.success == true) {
                                setEditAccount(undefined)
                                tableRef.current?.reload()
                            }
                        }).catch(p => {

                        })
                    }

                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Email")}
                                name="email"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                    { type: "email", message: t("Please enter a valid email!") },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Role")}
                                name="role"
                            >
                                <Select>
                                    <Select.Option value="admin">{t("Admin")}</Select.Option>
                                    <Select.Option value="manager">{t("Manager")}</Select.Option>
                                    <Select.Option value="user">{t("User")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {editAccount?._id == "" && <><Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Password")}
                                name="password"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Confirm Password")}
                                    name="confirmPassword"
                                    rules={[
                                        { required: true, message: t("Please confirm your password!") },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                                            },
                                        })
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col></>}
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

