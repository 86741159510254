import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import { Button, Card } from "antd";

import { DeleteFilled, KeyOutlined } from "@ant-design/icons";

import { AntDProTable } from "../../../components/antdProTable";

import { SubscriptionSchema } from "../../../model/subscriptionSchema.schema";
import { subscriptionService } from "../../../services/subscriptionService";
import moment from "moment";
import Swal from "sweetalert2";

//Start
export function AllSubscriptionApiKeyListPage(prop: { subscription: string }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  let tableRef = useRef<ActionType>();

  const columns: ProColumns<SubscriptionSchema>[] = [
    {
      title: t("API Key"),
      dataIndex: "_id",
      hideInSearch: true,
      hideInTable: false,
      sorter: false,
    },
    {
      title: t("Create Date"),
      dataIndex: "createDate",
      hideInSearch: true,
      defaultSortOrder:"descend",
      hideInTable: false,
      sorter: false,
      renderText(text, record, index, action) {
        return moment(new Date(text)).format("yyyy-MM-DD HH:mm:ss");
      },
    },

    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
           <Button
              title={t("Show")}
              type="link"
              onClick={() => {
                subscriptionService
                  .getApiKey(record._id || "")
                  .then((res) => {
                    if (res.success) {
                      Swal.fire("Secret",`<textarea style="border:0; width:90%; height:200px; text-align:center;" readonly>${res.data}</textarea>`);
                    }
                  });
              }}
            >
              <KeyOutlined />
            </Button>
            <Button
              title={t("Delete")}
              type="link"
              onClick={() => {
                setLoading(true);
                subscriptionService
                  .deleteSubscriptionApiKey(record._id || "")
                  .then((res) => {
                    setLoading(false);
                    if (res.success) {
                      tableRef.current?.reload();
                    } else {
                    }
                  });
              }}
            >
              <DeleteFilled />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }} loading={loading}>
        <AntDProTable<SubscriptionSchema>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
          toolbar={{
            actions: [
              <Button
                onClick={() => {
                  setLoading(true);
                  subscriptionService
                    .generateSubscriptionApiKey(prop.subscription)
                    .then((res) => {
                      setLoading(false);
                      if (res.success == true) {
                        tableRef.current?.reload();
                      } else {
                        Swal.fire("Error", res.error?.message || "", "error");
                      }
                    });
                }}
                key="primary"
                type="primary"
              >
                {t("New API Key")}
              </Button>,
            ],
          }}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            /// setLoading(true);
            where.subscription = prop.subscription;
            subscriptionService
              .listAllSubscriptionApiKey({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                //setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>
    </>
  );
}
