import { DocumentDefinitionSchema } from "../model/documentDefinition";
import { User } from "../model/user"
import apiClient, { handleError } from "./apiClient"





export const accountService = {
  getAllCompany,
  editCompany,
  addCompany,

  isEmailExist,
  getAllUser,
  deleteUser,
  editUser,
  addtUser: addUser,
  lockUser,
  unlockUser,
  changeUserPassword,
  login
}
async function login(username: string, password: string) {
  try {


    let response = await apiClient
      .post<{
        success: true,
        data: string
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/auth/login`, { email: username, password: password });


    return response.data
  } catch (e) {
    return handleError(e);
  }
}
async function getAllUser(args: any, allUsers:boolean =false) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.displayName ? `displayName:eq:${args.where.displayName}` : "",
          args.where["role"] ? `role:eq:${args.where["role"]}` : "",
          args.where["email"] ? `email:eq:${args.where["email"]}` : ""
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
      allUsers: allUsers?"1":"0"
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: User[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/listAll?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteUser(id: string) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/delete`, { _id: id });

    if (response.data.success == true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function editUser(data: {
  "_id": string
  "role": string
  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/update`, data);

    if (response.data.success === true, data) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addUser(data: {
  "_id": string
  "role": string
  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/create`, data);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function isEmailExist(data: {

  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false
      }>(`/admin/users/isEmailExist`, data);
    return response.data
  } catch (e) {
    return handleError(e);
  }
}
async function changeUserPassword(data: { _id: string, password: string, confirmPassword: string }) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/resetAccountPassword`, data);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function lockUser(id: string, toPeriod: Date) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/lock`, { _id: id, toPeriod: toPeriod.toISOString() });

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function unlockUser(id: string) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/users/unlock`, { _id: id });

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAllCompany(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.name ? `displayName:like:${args.where.name}` : "",
          args.where["status"] ? `role:eq:${args.where["status"]}` : ""
        ]
          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: User[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/companies/listAll?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function editCompany(data: {
  "_id": string
  "role": string
  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/companies/update`, data);

    if (response.data.success === true, data) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addCompany(data: {
  confirmPassword: string
  email: string
  name: string
  password: string
  status: string
  subscriptionCalculationMethod: string
  subscriptionPeriod: [string, string]
  subscriptionToken: number,
  documentTypes:DocumentDefinitionSchema[]
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/companies/create`, data);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}