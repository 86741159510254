import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType } from "@ant-design/pro-components";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Row,
  Skeleton,
  Tabs
} from "antd";

import { useNavigate, useParams } from "react-router-dom";

import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
import { SubscriptionSchema } from "../../../model/subscriptionSchema.schema";
import { subscriptionService } from "../../../services/subscriptionService";
import { AllSubscriptionApiKeyListPage } from "./allSubscriptionApiKeyList.page";
import { AllSubscriptionHistoryListPage } from "./allSubscriptionHistory.page";
import { jwtDecode } from "jwt-decode";
import { AllSubscriptionSummaryPage } from "./allSubscriptionSummary.page";
//Start
export function SubscriptionAdminPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [debug, setDebug] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const [document, setDocument] = useState<SubscriptionSchema>();
  let tableRef = useRef<ActionType>();
  useEffect(() => {
    reloadApplication();
    setDebug(localStorage.getItem("debug") == "1" ? true : false);
  }, []);
  function reloadApplication() {
    setLoading(true);
    subscriptionService.getOneDetailSubscription(id || "").then((res) => {
      setLoading(false);
      if (res.success == true) {
        setDocument(res.result);
      }
    });
  }
  function save() {
    setLoading(true);
  }

  return (
    <>

      <Skeleton loading={loading} active avatar>
        <Card>
          <ButtonGroup style={{ float: "right" }}>
            <Button type="primary" disabled={!hasChange} onClick={save}>
              {t("Save Changes")}
            </Button>
          </ButtonGroup>
          <div style={{ clear: "both" }} />
          <Descriptions>
            <Descriptions.Item label={t("Name")}>
              {document?.subscriptionName}
            </Descriptions.Item>
            <Descriptions.Item label={t("Description")}>
              {document?.subscriptionDescription}
            </Descriptions.Item>
            <Descriptions.Item label={t("Status")}>
              {t(document?.subscriptionStatus || "")}
            </Descriptions.Item>
            <Descriptions.Item label={t("Calculation Method")}>
              {t(document?.subscriptionCalculationMethod || "")}
            </Descriptions.Item>
            {document?.subscriptionCalculationMethod == "Token" && <Descriptions.Item label={t("Token")}>
              {document?.subscriptionToken || ""}
            </Descriptions.Item>}
            {document?.subscriptionCalculationMethod == "Time" && <Descriptions.Item label={t("Begin Date")}>
              {moment(document?.subscriptionBeginDate || new Date()).format(
                "yyyy-MM-DD HH:mm:ss"
              )}
            </Descriptions.Item>}
            {document?.subscriptionCalculationMethod == "Time" && <Descriptions.Item label={t("Expire Date")}>
              {moment(document?.subscriptionExpireDate || new Date()).format(
                "yyyy-MM-DD HH:mm:ss"
              )}
            </Descriptions.Item>}
          </Descriptions>

          <Tabs
            defaultActiveKey="1"
            items={
              [
                {
                  key: "Summary",
                  label: t("Subscription Summary"),
                  children: (
                    <>
                      <Row gutter={16}>
                        <Col sm={24}>
                          <AllSubscriptionSummaryPage subscription={id || ""} />
                        </Col>
                      </Row>
                    </>
                  ),
                },
                {
                  key: "Key",
                  label: t("API Key"),
                  children: (
                    <>
                      <Row gutter={16}>
                        <Col sm={24}>
                          <AllSubscriptionApiKeyListPage subscription={id || ""} />
                        </Col>
                      </Row>
                    </>
                  ),
                },
                {
                  key: "History",
                  label: t("History"),
                  children: (
                    <>
                      <Row gutter={16}>
                        <Col md={24} sm={24}><AllSubscriptionHistoryListPage subscription={id || ""} /></Col>
                      </Row>
                    </>
                  ),
                },
              ].filter((p) => p != undefined) as {
                key: string;
                label: string;
                children: React.ReactNode;
              }[]
            }
          />
        </Card>
      </Skeleton>
    </>
  );
}
