import { useTranslation } from "react-i18next";
// ** React Imports
import { useNavigate } from "react-router-dom";
// ** Next Imports

import { Button, Card, Form, Input, Space } from "antd";
import { accountService } from "../../services/accountService";
import { useState } from "react";
import Swal from "sweetalert2";
import ChangeLanguageButton from "../../components/changeLanguage";

export function LoginPage() {
  const { t } = useTranslation(); // Here's where i18n comes from.
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values: { email: string; password: string }) => {
    setLoading(true);
    accountService.login(values.email, values.password).then((d) => {
      setLoading(false);
      if (d.success == true) {
        localStorage.setItem("userToken", d.data);
        navigate("/dashboard");
      } else {
        Swal.fire("Error", "Login Failed", "error");
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        <img src="/logo512.png" alt="logo" style={{ width: 200, height: 200 }} />
        <Card
          title={
            <div style={{ fontSize: "25px", textAlign: "center" }}>
              {t("SYSTEM_NAME")}
            </div>
          }
          style={{
            width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5  ",
            fontSize: "100px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Form onFinish={onFinish} autoComplete="off" layout="vertical">
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[
                { required: true, message: t("Please input your email!") },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                { required: true, message: t("Please input your password!") },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Space>
                <ChangeLanguageButton />
                <Button loading={loading} type="primary" htmlType="submit">
                  {t("Login")}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
}
