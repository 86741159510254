import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType } from "@ant-design/pro-components";
import {
  Breadcrumb,
  Card,
  Descriptions,
  Empty,
  Input,
  Skeleton,
  Space,
  Tabs,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Form,
  Select,
} from "antd";

import { TagsOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import EditableTable from "../../components/editableTable";
import { DocumentSchema } from "../../model/documentSchema.schema";
import { documentTypeServices } from "../../services/documentService";
import ButtonGroup from "antd/lib/button/button-group";
import EditableDescription from "../../components/editableDescription";
import { SubscriptionSchema } from "../../model/subscriptionSchema.schema";
import { useForm } from "antd/es/form/Form";
import Base64Viewer from "../../components/documentPreviewer";
//Start
export function DocumentContentPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [debug, setDebug] = useState<boolean>(false);
  const [redetect, setRedetect] = useState<boolean>(false);
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [redetectForm] = useForm();
  const [subscriptionOptions, setSubscriptionOptions] = useState<
    SubscriptionSchema[]
  >([]);
  const [document, setDocument] = useState<DocumentSchema>();
  let tableRef = useRef<ActionType>();

  useEffect(() => {
    reloadApplication();
    documentTypeServices.getSubscriptionOptions().then((res) => {
      if (res.success == true) {
        setSubscriptionOptions(res.result);
      }
    });
    setDebug(localStorage.getItem("debug") == "1" ? true : false);
  }, []);
  function reloadApplication() {
    setLoading(true);
    documentTypeServices.getOneDetailDocument(id || "").then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success == true) {
        if (res.result.report && Array.isArray(res.result.report?.LineDetails)) {
          res.result.report.LineDetails = res.result.report.LineDetails.map(
            (p: any, i: any) => {
              return { id: i, ...p };
            }
          );
        }
        setDocument(res.result);
      }
    });
  }
  function save() {
    setLoading(true);
    documentTypeServices
      .saveDocument({
        _id: document?._id || "",
        CustomerDetail: document?.report?.CustomerDetail,
        DocumentDetail: document?.report?.DocumentDetail,
        LineDetails: document?.report?.LineDetails || [],
      })
      .then((res) => {
        if (res.success) {
          setHasChange(false);
          reloadApplication();
        }
      });
  }
  function submitForApproval() {
    documentTypeServices.submitForApproval(id || "").then((res) => {
      if (res.success) {
        setHasChange(false);
        reloadApplication();
      }
    });
  }
  /* function redetectDocument() {
    documentTypeServices.redetectDocument(id || "").then((res) => {
      if (res.success) {
        setHasChange(false);
        reloadApplication();
      }
    });
  }*/
  return (
    <>
      <Breadcrumb
        itemRender={(route, params, routes, paths) => {
          if (route.href) {
            return <Link to={route.href || "/"}> {route.title} </Link>;
          }
          return route.title;
        }}
        style={{ marginBottom: 30, marginTop: 30 }}
        items={[
          {
            href: `/dashboard/upload-doucuments/`,
            title: (
              <>
                <TagsOutlined />
                <span>{t("Upload Documents")}</span>
              </>
            ),
          },

          {
            title: t("Document"),
          },
        ]}
      />

      <Skeleton loading={loading} active avatar>
        <Card>
          <ButtonGroup style={{ float: "right" }}>
            {document?.status == "OCRCompleted" ? (
              <Button type="primary" disabled={!hasChange} onClick={save}>
                {t("Save Changes")}
              </Button>
            ) : undefined}

            {document?.status == "OCRCompleted" ? (
              <Button
                disabled={hasChange}
                onClick={() => {
                  setRedetect(true);
                }}
              >
                {t("Detect Again")}
              </Button>
            ) : undefined}
            {document?.status == "OCRCompleted" ? (
              <Button disabled={hasChange} onClick={submitForApproval}>
                {t("Submit For Approval")}
              </Button>
            ) : undefined}
          </ButtonGroup>
          <div style={{ clear: "both" }} />
          <Descriptions>
            <Descriptions.Item label={t("Document Name")}>
              {document?.documentName}
            </Descriptions.Item>
            <Descriptions.Item label={t("Status")}>
              {t(document?.status.toString() || "")}
            </Descriptions.Item>
            <Descriptions.Item label={t("Upload Date")}>
              {moment(document?.createDate || new Date()).format(
                "yyyy-MM-DD HH:mm:ss"
              )}
            </Descriptions.Item>
            <Descriptions.Item label={t("Last Update Date")}>
              {moment(document?.updateDate || new Date()).format(
                "yyyy-MM-DD HH:mm:ss"
              )}
            </Descriptions.Item>
            {document?.meta?.department != undefined && <Descriptions.Item label={t("Department")}>
              {document?.meta?.department}
            </Descriptions.Item>}
            {document?.meta?.subsidiary != undefined && <Descriptions.Item label={t("Subsidiary")}>
              {document?.meta?.subsidiary}
            </Descriptions.Item>}
            {document?.meta?.fromAddress != undefined && <Descriptions.Item label={t("From Address")}>
              {document?.meta?.fromAddress}
            </Descriptions.Item>}
          </Descriptions>

          <Tabs
            defaultActiveKey="1"
            items={
              [
                {
                  key: "Document",
                  label: t("Document"),
                  children: (
                    <>
                      <Row gutter={16}>
                        <Col md={12} sm={24}>
                          {document?.pages && document?.pages.length > 0 ? (
                            <Tabs
                              defaultActiveKey="document-page-0"
                              tabPosition={"top"}
                              items={document?.pages.map(
                                (p: string, i: number) => {
                                  return {
                                    key: "document-page-" + i,
                                    label: `P.${i + 1}`,
                                    children: (
                                      <>
                                        <Space
                                          style={{
                                            width: "100%",
                                            overflow: "auto",
                                            position: "relative",
                                          }}
                                        >
                                          <img
                                            src={p}
                                            style={{
                                              width: "100%",
                                              maxWidth: 1000,
                                              height: "auto",
                                            }}
                                          />
                                        </Space>
                                      </>
                                    ),
                                  };
                                }
                              )}
                            />
                          ) : (
                            <Base64Viewer base64Data={document?.documentContent} />

                          )}
                        </Col>
                        {document?.report ? (
                          <Col md={12} sm={24}>
                            {document.status == "OCRCompleted" ? (
                              <EditableDescription
                                title={t("Customer Info")}
                                columns={
                                  document.usingSchema
                                    ? document.usingSchema.customerDefinition.map(
                                      (p, i) => {
                                        return {
                                          title: p.columnName,
                                          dataIndex: p.columnName,
                                          key: `document-line-info-${i}-${p.columnName}`,
                                        };
                                      }
                                    )
                                    : []
                                }
                                datasource={
                                  document?.report?.CustomerDetail
                                    ? document?.report?.CustomerDetail
                                    : {}
                                }
                                onSave={async (id, record, originRow) => {
                                  let data = { ...document };
                                  setHasChange(true);
                                  if (data.report?.CustomerDetail) {
                                    data.report.CustomerDetail = {
                                      ...data.report.CustomerDetail,
                                      ...record,
                                    };
                                  }
                                  setDocument(data);
                                }}
                              />
                            ) : (
                              <Descriptions
                                title={t("Customer Info")}
                                items={Object.keys(
                                  document.report.CustomerDetail
                                ).map((p, i) => {
                                  return {
                                    key: `document-customer-info-${i}`,
                                    label: p,
                                    children:
                                      document.report?.CustomerDetail[p],
                                  };
                                })}
                              />
                            )}
                            {document.status == "OCRCompleted" ? (
                              <EditableDescription
                                title={t("Document Info")}
                                columns={
                                  document.usingSchema
                                    ? document.usingSchema.documentDefinition.map(
                                      (p, i) => {
                                        return {
                                          title: p.columnName,
                                          dataIndex: p.columnName,
                                          key: `document-line-info-${i}-${p.columnName}`,
                                        };
                                      }
                                    )
                                    : []
                                }
                                datasource={
                                  document?.report?.DocumentDetail
                                    ? document?.report?.DocumentDetail
                                    : {}
                                }
                                onSave={async (id, record, originRow) => {
                                  setHasChange(true);
                                  let data = { ...document };
                                  if (data.report?.DocumentDetail) {
                                    data.report.DocumentDetail = {
                                      ...data.report.DocumentDetail,
                                      ...record,
                                    };
                                  }
                                  setDocument(data);
                                }}
                              />
                            ) : (
                              <Descriptions
                                title={t("Document Info")}
                                items={Object.keys(
                                  document.report.DocumentDetail
                                ).map((p, i) => {
                                  return {
                                    key: `document-document-info-${i}`,
                                    label: p,
                                    children:
                                      document.report?.DocumentDetail[p],
                                  };
                                })}
                              />
                            )}
                            <span style={{ fontWeight: 600, fontSize: 16 }}>
                              {t("Line Details")}
                            </span>
                            {document.status == "OCRCompleted" ? (
                              <EditableTable
                                onsave={async (rowKey, newData, row) => {
                                  setHasChange(true);
                                  let data = { ...document };
                                  if (data.report?.LineDetails && Array.isArray(data.report?.LineDetails)) {
                                    if (newData.id == -1) {
                                      newData.id =
                                        data.report?.LineDetails.length;
                                      data.report.LineDetails.push(newData);
                                      setDocument(data);
                                    } else {
                                      data.report.LineDetails =
                                        data.report.LineDetails.map((p) => {
                                          if (p.id == newData.id) {
                                            return newData;
                                          } else {
                                            return p;
                                          }
                                        });
                                      setDocument(data);
                                    }
                                  }
                                }}
                                onDelete={async (id) => {
                                  setHasChange(true);
                                  let data = { ...document };
                                  if (data.report?.LineDetails && Array.isArray(data.report?.LineDetails)) {
                                    data.report.LineDetails =
                                      data.report.LineDetails.filter((p, i) => {
                                        return p.id != id;
                                      });
                                    data.report.LineDetails =
                                      data.report.LineDetails.map((p, i) => {
                                        p.id = i;
                                        return p;
                                      });
                                  }
                                  setDocument(data);
                                }}
                                dataSource={
                                  document.report.LineDetails
                                    ? document.report.LineDetails
                                    : []
                                }
                                columns={
                                  document.usingSchema
                                    ? document.usingSchema.lineItemsDefinition.map(
                                      (p, i) => {
                                        return {
                                          title: p.columnName,
                                          dataIndex: p.columnName,
                                          tooltip: p.description,
                                        };
                                      }
                                    )
                                    : []
                                }
                              />
                            ) : (
                              <Table
                                dataSource={document.report.LineDetails}
                                columns={
                                  document.usingSchema &&
                                  document.usingSchema.lineItemsDefinition.map(
                                    (p, i) => {
                                      return {
                                        title: p.columnName,
                                        dataIndex: p.columnName,
                                        key: `document-line-info-${i}-${p.columnName}`,
                                      };
                                    }
                                  )
                                }
                              ></Table>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </>
                  ),
                },
                debug
                  ? {
                    key: "OCR Result",
                    label: t("OCR Result"),
                    children: (
                      <>
                        <Tabs
                          defaultActiveKey="1"
                          tabPosition={"left"}
                          items={document?.pages.map(
                            (p: string, i: number) => {
                              return {
                                key: "document-ocr-" + i,
                                label: `P.${i + 1}`,
                                children: (
                                  <>
                                    <Space
                                      style={{
                                        width: "100%",
                                        overflow: "auto",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={p}
                                        style={{
                                          width:
                                            document?.ocrResult &&
                                              document?.ocrResult.length > i
                                              ? document?.ocrResult[i].width
                                              : "auto",
                                          height:
                                            document?.ocrResult &&
                                              document?.ocrResult.length > i
                                              ? document?.ocrResult[i].height
                                              : "auto",
                                        }}
                                      />
                                      <div
                                        style={{
                                          position: "relative",
                                          width:
                                            document?.ocrResult &&
                                              document?.ocrResult.length > i
                                              ? document?.ocrResult[i].width
                                              : "auto",
                                          height:
                                            document?.ocrResult &&
                                              document?.ocrResult.length > i
                                              ? document?.ocrResult[i].height
                                              : "auto",
                                        }}
                                      >
                                        {document?.ocrResult &&
                                          document?.ocrResult.length > i &&
                                          document.ocrResult[
                                            i
                                          ].text_blocks.map((ocr, j) => {
                                            return (
                                              <span
                                                key={`ocr-page-${i}-b-${j}`}
                                                style={{
                                                  position: "absolute",
                                                  fontSize: 11,
                                                  backgroundColor:
                                                    "rgba(255,255,255,0.1)",
                                                  left:
                                                    (ocr[0] /
                                                      document.ocrResult[i]
                                                        .width) *
                                                    100 +
                                                    "%",
                                                  top:
                                                    ((document.ocrResult[i]
                                                      .height -
                                                      ocr[1]) /
                                                      document.ocrResult[i]
                                                        .height) *
                                                    100 +
                                                    "%",
                                                }}
                                              >
                                                {ocr[4]}
                                              </span>
                                            );
                                          })}
                                      </div>
                                    </Space>
                                  </>
                                ),
                              };
                            }
                          )}
                        />
                      </>
                    ),
                  }
                  : undefined,
                document?.gpt && debug
                  ? {
                    key: "GPT Result",
                    label: t("GPT Result"),
                    children: (
                      <>
                        <Input.TextArea
                          style={{ width: "100%", height: "100%" }}
                          value={document?.gpt || ""}
                        ></Input.TextArea>
                      </>
                    ),
                  }
                  : undefined,
              ].filter((p) => p != undefined) as {
                key: string;
                label: string;
                children: React.ReactNode;
              }[]
            }
          />
        </Card>
      </Skeleton>
      <Modal
        open={redetect}
        title=""
        okText={t("Save")}
        cancelText={t("Cancel")}
        onOk={() => {
          redetectForm.submit();
        }}
        onCancel={() => {
          setRedetect(false);
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Form
          name="redetectForm"
          layout="vertical"
          form={redetectForm}
          onFinish={(props: { subscription: string }) => {
            documentTypeServices
              .redetectDocument(id || "", props.subscription)
              .then((res) => {
                if (res.success) {
                  setHasChange(false);
                  reloadApplication();
                  setRedetect(false);
                }
              });
          }}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="subscription"
                label={t("Subscription")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={subscriptionOptions.map((p) => {
                    return {
                      label: p.subscriptionName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
