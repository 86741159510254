import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components"
import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Steps } from "antd"

import { DeleteOutlined, EditOutlined, InboxOutlined, KeyOutlined, LockOutlined, SearchOutlined, UnlockOutlined } from "@ant-design/icons"

import { useNavigate } from "react-router-dom"

import { AntDProTable } from "../../../components/antdProTable"
import { accountService } from "../../../services/accountService"

import { useForm } from "antd/es/form/Form"
import Swal from "sweetalert2"
import { User } from "../../../model/user"
import { Company, CompanyStatus } from "../../../model/company"
import Dragger from "antd/es/upload/Dragger"
import { RcFile } from "antd/es/upload"
import { DocumentDefinitionSchema } from "../../../model/documentDefinition"
import { Dayjs } from "dayjs"
//Start
export function CompanyListPage() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [editCompany, setEditCompany] = useState<Company>()
    const [documentTypes, setDocumentTypes] = useState<DocumentDefinitionSchema[]>([])

    const [editCompanyForm] = useForm();
    const [step, setStep] = useState<number>(0)
    let tableRef = useRef<ActionType>()
    const [subscriptionCalculationMethod, setSubscriptionCalculationMethod] =
        useState<string>();
    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])
    const generatePassword = () => {
        let charset = "";
        let newPassword = "";

        charset += "!@#$%^&*()";
        charset += "0123456789";
        charset += "abcdefghijklmnopqrstuvwxyz";
        charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        for (let i = 0; i < 12; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }

        return newPassword;
    };
    const columns: ProColumns<Company>[] = [
        {
            title: t("Compnay Name"),
            dataIndex: "name",
            sorter: true,
        },
        {
            title: t("Status"),
            dataIndex: "status",
            sorter: true,
            valueEnum: {
                "enable": { text: t("Enable") },
                "disable": { text: t("Disable") }
            }
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <>
                        <Button
                            title={t("Edit")}
                            type="link"
                            onClick={() => {
                                setEditCompany(record)
                                editCompanyForm.setFieldsValue(record)
                            }}
                        >
                            <EditOutlined />
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Card style={{ marginTop: 20 }}>
                <AntDProTable<Company>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                        tableRef.current = ref.current
                    }}
                    pageSizeOptions={[10, 20, 50, 100, 200]}
                    columns={columns}
                    // columns={columns.filter((c) => c.key !== "remove")}
                    rowKey="id"
                    scroll={{ x: '80vw' }}
                    // scroll={{ x: "max-content" }}
                    toolbar={{

                        actions: [
                            <Button onClick={() => {
                                setEditCompany({ _id: "", name: "", status: CompanyStatus.Enable, rootCompany: false })
                                editCompanyForm.setFieldsValue({ _id: "", name: "", status: CompanyStatus.Enable, rootCompany: false })
                            }} key="primary" type="primary">
                                {t("New Company")}
                            </Button>,
                        ],
                    }}
                    request={(
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy,
                        order,
                        cb
                    ) => {
                        if (filterByStatus.length > 0) {
                            where.Status = filterByStatus
                        }
                        setLoading(true);
                        accountService.getAllCompany({
                            params,
                            sort,
                            filter,
                            skip,
                            take,
                            where,
                            sortBy,
                            order,
                        }).then(res => {
                            setLoading(false);
                            if (res.success == true) {
                                cb({
                                    data: res.result,
                                    total: res.recordTotal,
                                    success: true,
                                    page: params.current,
                                })
                            } else {
                                cb({
                                    data: [],
                                    total: 0,
                                    success: false,
                                    page: params.current,
                                })
                            }
                        }).catch(err => {
                            setLoading(false);
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        })
                    }}
                />
            </Card>

            <Modal

                title={t("")}
                onClose={() => {
                    setEditCompany(undefined)
                    setStep(0);
                }}
                onCancel={() => {
                    setEditCompany(undefined)
                    setStep(0);
                }}
                centered
                open={editCompany !== undefined}
                footer={<>
                    <Button style={{ display: step != 0 ? "inline" : "none" }} onClick={() => {
                        setStep((prev) => Math.max(prev - 1, 0))
                    }}>{t("Back")}</Button>
                    <Button type="primary" style={{ display: step != 4 ? "inline" : "none" }} onClick={async () => {
                        try {
                            if (step == 0) {
                                const success = await editCompanyForm.validateFields(["name", "status"])
                            } else if (step == 1) {
                                const success = await editCompanyForm.validateFields(["email", "password", "confirmPassword"])
                                const serverSuccess = await accountService.isEmailExist({ "email": editCompanyForm.getFieldValue("email") });
                                if (serverSuccess.success == false) {
                                    editCompanyForm.setFields([{
                                        name: "email",
                                        errors: ["Email is duplicated"]
                                    }])
                                    return;
                                }
                            } else if (step == 2) {
                                const success = await editCompanyForm.validateFields(["subscriptionCalculationMethod", "subscriptionToken", "subscriptionPeriod"])
                            } else if (step == 3) {

                            }
                            setStep((prev) => Math.min(prev + 1, 4))
                        } catch (error) {
                            console.log(error)
                        }
                    }}>{t("Next")}</Button>
                    <Button type="primary" style={{ display: step == 4 ? "inline" : "none" }} onClick={() => editCompanyForm.submit()}>{t("Save")}</Button>
                    <Button onClick={() => setEditCompany(undefined)}>{t("Cancel")}</Button>
                </>}
                width={1000}
            >
                <div style={{ padding: 20 }}>
                    <Steps
                        current={step}
                        items={[
                            {
                                title: t('Company Information')
                            },
                            {
                                title: t('Service Account')
                            },
                            {
                                title: t('Subscription')
                            },
                            {
                                title: t('Documet Types')
                            },
                            {
                                title: t('Confirmation')
                            },
                        ]}
                    />
                    <Divider style={{ opacity: "0" }} />
                    <Form name="editCompanyForm"
                        layout="vertical"
                        onValuesChange={({
                            subscriptionCalculationMethod,
                        }: {
                            subscriptionCalculationMethod: "Token" | "Time";
                        }) => {
                            if (subscriptionCalculationMethod) {
                                setSubscriptionCalculationMethod(subscriptionCalculationMethod);
                            }
                        }}
                        form={editCompanyForm}
                        initialValues={editCompany}
                        onFinish={(props: {
                            confirmPassword: string
                            email: string
                            name: string
                            password: string
                            status: string
                            subscriptionCalculationMethod: string
                            subscriptionPeriod: [Dayjs, Dayjs]
                            subscriptionToken: number
                        }) => {

                            let period: [string, string] = ["", ""]
                            let token = 0
                            if (props.subscriptionCalculationMethod == "Token") {
                                period = ["", ""];
                                token = props.subscriptionToken;
                            } else {
                                token = 0;
                                period = [props.subscriptionPeriod[0].toISOString(), props.subscriptionPeriod[1].toISOString()];
                            }
                            accountService.addCompany({
                                ...props,
                                subscriptionPeriod: period,
                                subscriptionToken: token,
                                documentTypes: documentTypes
                            }).then(d => {
                                if (d.success == true) {
                                    setEditCompany(undefined)
                                    tableRef.current?.reload()
                                }else{
                                    Swal.fire("Error", JSON.stringify(d.error), "error");
                                }
                            }).catch(p => {

                            })
                        }}>

                        <Row style={{ display: step == 0 ? "block" : "none" }} gutter={16}>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    required={true}
                                    label={t("Company Name")}
                                    name="name"
                                    rules={[{ required: true, message: t("Please input the company name!") }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    required={true}
                                    label={t("Status")}
                                    name="status"
                                >
                                    <Select>
                                        <Select.Option value={CompanyStatus.Enable}>{t("Enable")}</Select.Option>
                                        <Select.Option value={CompanyStatus.Disable}>{t("Disable")}</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ display: step == 1 ? "block" : "none" }} gutter={16}>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Email")}
                                    name="email"
                                    required={true}
                                    rules={[{ required: true, message: t("Please input the email!") },
                                    { type: 'email', message: t("The input is not valid E-mail!") }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Password")}
                                    name="password"
                                    rules={[
                                        { required: true, message: t("Please input the new password!") },
                                        { min: 8, message: t("Password must be at least 8 characters") },
                                    ]}
                                >

                                    <Input.Password addonBefore={<LockOutlined style={{ cursor: "pointer" }} onClick={
                                        () => {
                                            editCompanyForm.setFieldValue("password", generatePassword())
                                        }
                                    } />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Confirm Password")}
                                    name="confirmPassword"
                                    rules={[
                                        { required: true, message: t("Please confirm your password!") },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                                            },
                                        })
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ display: step == 2 ? "block" : "none" }} gutter={16}>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Calculation Method")}
                                    name="subscriptionCalculationMethod"
                                >
                                    <Radio.Group optionType="button">
                                        <Radio value={"Time"}>{t("Time")}</Radio>
                                        <Radio value={"Token"}>{t("Token")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item label={t("Token")} name="subscriptionToken"
                                    rules={[
                                        { type: "number", message: t("Please input number") },
                                        { required: subscriptionCalculationMethod == "Token", message: t("Please input number") }
                                    ]}
                                    required={subscriptionCalculationMethod == "Token"}
                                >
                                    <InputNumber
                                        autoComplete="off"
                                        style={{ width: "100%" }}

                                        disabled={subscriptionCalculationMethod != "Token"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={12} xs={24}>
                                <Form.Item label={t("Date Period")} name="subscriptionPeriod"
                                    required={subscriptionCalculationMethod == "Time"}
                                    rules={[
                                        { required: subscriptionCalculationMethod == "Time", message: t("Please input a valid date period") }
                                    ]}>
                                    <DatePicker.RangePicker
                                        autoComplete="off"
                                        style={{ width: "100%" }}
                                        disabled={subscriptionCalculationMethod != "Time"}

                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ display: step == 3 ? "block" : "none" }} gutter={16}>
                            <Dragger name={'file'}
                                multiple={true}
                                accept={"application/json"}
                                maxCount={1}
                                beforeUpload={(file: RcFile, FileList: RcFile[]) => {
                                    file.text().then((text) => {
                                        try {
                                            const list = JSON.parse(text)
                                            if (Array.isArray(list) && list.length > 0) {
                                                const t = []
                                                for (let i = 0; i < list.length; i++) {
                                                    let def = list[i] as DocumentDefinitionSchema;
                                                    if (def.documentTypeName
                                                        && def.documentTypeName != ""
                                                        && def.customerDefinition
                                                        && Array.isArray(def.customerDefinition)
                                                        && def.documentDefinition
                                                        && Array.isArray(def.documentDefinition)
                                                        && def.lineItemsDefinition
                                                        && Array.isArray(def.lineItemsDefinition)
                                                    ) {
                                                        t.push(def)
                                                    }
                                                }
                                                setDocumentTypes(t);
                                            }
                                        } catch (e) {
                                            console.error(e)
                                        }
                                    })
                                    return false
                                }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>

                                <p className="ant-upload-hint">
                                    {t("Support for a single or bulk upload.")}
                                </p>
                            </Dragger>
                            <AntDProTable<DocumentDefinitionSchema>
                                filterType={"light"}
                                size={"small"}
                                onRefCallback={(ref: any) => {
                                    tableRef.current = ref.current
                                }}
                                pageSizeOptions={[10, 20, 50, 100, 200]}
                                columns={[{
                                    title: t("Document Type Name"),
                                    dataIndex: "documentTypeName",
                                    hideInSearch: true,
                                },
                                {
                                    title: t("Enable"),
                                    dataIndex: "enable",
                                    hideInSearch: true,
                                    renderText(text, record, index, action) {
                                        return text ? t("Yes") : t("No");
                                    },
                                },]}
                                // columns={columns.filter((c) => c.key !== "remove")}
                                rowKey="id"
                                dataSource={documentTypes}
                                scroll={{ x: '80vw' }}
                            />
                        </Row>
                        <Row style={{ display: step == 4 ? "block" : "none" }} gutter={16}>
                            <Descriptions title={t("Company Information")} items={[{
                                label: t("Company Name"),
                                children: <>{editCompanyForm.getFieldValue("name")}</>
                            }, {
                                label: t("Status"),
                                children: <>{t(editCompanyForm.getFieldValue("status"))}</>
                            }]} />
                            <Descriptions title="Service Account" items={[{
                                label: t("Email"),
                                children: <>{editCompanyForm.getFieldValue("email")}</>
                            }]} />
                            <Descriptions title={t("Subscription")} items={[{
                                label: t("Calculation Method"),
                                children: <>{editCompanyForm.getFieldValue("subscriptionCalculationMethod")}</>
                            }, {
                                label: t("Token"),
                                children: <>{editCompanyForm.getFieldValue("subscriptionCalculationMethod") == "Token" ? editCompanyForm.getFieldValue("subscriptionToken") : "N/A"}</>
                            }, {
                                label: t("Date Period"),
                                children: <>{editCompanyForm.getFieldValue("subscriptionCalculationMethod") == "Token" ? "N/A" : Array.isArray(editCompanyForm.getFieldValue("subscriptionPeriod")) ? `${editCompanyForm.getFieldValue("subscriptionPeriod")[0].format("YYYY-MM-DD")} - ${editCompanyForm.getFieldValue("subscriptionPeriod")[1].format("YYYY-MM-DD")}` : "N/A"}</>
                            }]} />
                            <Descriptions title={t("Document Types")} items={[{
                                label: t("Document Types"),
                                children: <>{documentTypes.map(p => t(p.documentTypeName)).join(",")}</>
                            }]} />
                        </Row>
                    </Form>

                </div>
            </Modal >
        </>
    )
}

