import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Button, Card, Form, Input, Spin } from "antd"


const ForgetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
 
  const onFinish = (values: any) => {
    setLoading(true)    
  }

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100 %",
          minHeight: "100vh",
          display: "flex",
          flexDirection:"column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        <Card
          title={
            <div style={{ fontSize: "25px", textAlign: "center" }}>      
             
              {t("Forget Password")}
            </div>
          }
          style={{
            width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5  ",
            fontSize: "100px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Spin spinning={loading}>
            <Form
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                label={t("Company Code")}
                name="companyCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your company code!",
                  },
                ]}
              >
                <Input
                // style={{ width: 350 }}
                />
              </Form.Item>
              <Form.Item
                label={t("Username")}
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                // style={{ width: 350 }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {t("Forget password")}
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  type="dashed"
                  onClick={() => {
                    navigate("/admin-login")
                  }}
                >
                  {t("Back")}
                </Button>
           
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  )
}

export default ForgetPassword
