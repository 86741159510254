import { SubscriptionSchema } from "../model/subscriptionSchema.schema"
import { User } from "../model/user"
import apiClient, { handleError } from "./apiClient"





export const subscriptionService = {
  getAllSubscription,
  editSubscription,
  addtSubscription,
  getOneDetailSubscription,
  listAllSubscriptionApiKey,
  generateSubscriptionApiKey,
  deleteSubscriptionApiKey,
  getApiKey,
  listAllSubscriptionHistory,
  listAllSubscriptionSummary
}

async function getAllSubscription(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [ ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: SubscriptionSchema[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/listAll?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getOneDetailSubscription(_id: string) {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: any | null;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/subscriptions/getOneDetailSubscription/${_id}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}

async function editSubscription(data: {
  _id:string
  ownerId: string;
  subscriptionName: string;
  subscriptionDescription: string;
  subscriptionStatus: string;
  subscriptionBeginDate: number;
  subscriptionExpireDate: number;
  subscriptionToken: number;
  subscriptionCalculationMethod: string;
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/update`, data);

    if (response.data.success === true, data) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addtSubscription(data: {
  ownerId: string;
  subscriptionName: string;
  subscriptionDescription: string;
  subscriptionStatus: string;
  subscriptionBeginDate: number;
  subscriptionExpireDate: number;
  subscriptionToken: number;
  subscriptionCalculationMethod: string;
}) {
  try {
    let response = await apiClient
      .post<{
        success: true
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/create`, data);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function listAllSubscriptionApiKey(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.subscription ? `subscription:eq:${args.where.subscription}` : ""        
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: User[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/listAllSubscriptionApiKey?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function listAllSubscriptionSummary(subscription: string) {
  try {

    let consolidArgs = {
      subscription
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: User[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/apiSummary?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function generateSubscriptionApiKey(subscription:string) {
  try {

   
    let response = await apiClient
      .get<{
        success: true,
      } | {
        success: false,
        error?: {
          message: string
          code: string;
        }
      }>(`/admin/subscriptions/generateAPIKey/${subscription}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteSubscriptionApiKey(subscription:string) {
  try {

   
    let response = await apiClient
      .get<{
        success: true,
      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/deleteAPIKey/${subscription}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getApiKey(subscription:string) {
  try {

   
    let response = await apiClient
      .get<{
        success: true,
        data:string
      } | {
        success: false,
        error?: {
          message: string
          code: string;
        }
      }>(`/admin/subscriptions/getApiKey/${subscription}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function listAllSubscriptionHistory(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.subscription ? `subscription:eq:${args.where.subscription}` : ""        
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        success: true,
        result: User[],
        recordTotal: number

      } | {
        success: false,
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/subscriptions/listAllSubscriptionHistory?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}