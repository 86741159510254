import { DocumentDefinitionSchema } from "../model/documentDefinition";
import { SubscriptionSchema } from "../model/subscriptionSchema.schema";
import { User } from "../model/user";
import apiClient, { handleError } from "./apiClient";

export const documentTypeServices = {
  getAllDocumentTypeDatatable,
  getAllDocumentTypeOptions,
  deleteDocumentType,
  editDocumentType,
  addDocumentType,
  getAllDocumentsDatatable,
  listMyUploadDocument,
  getAllApprovalDocumentDatatable,
  getOneDetailDocument,
  addDocument,
  addDocuments,
  addBulkDocument,
  saveDocument,
  deleteDocument,
  redetectDocument,
  submitForApproval,
  getDocumentsSummary,
  getMyDocumentsSummary,
  rejectDocument,
  approveDocument,
  getSubscriptionOptions
};
async function getAllDocumentTypeDatatable(args: any) {
  try {
    let consolidArgs = {
      offset: String((args.params.current - 1) * args.params.pageSize),
      limit: String(args.take),
      where: args.where
        ? [
            args.where.displayName
              ? `displayName:eq:${args.where.displayName}`
              : "",
            args.where["role"] ? `role:eq:${args.where["role"]}` : "",
            args.where["email"] ? `email:eq:${args.where["email"]}` : "",
          ]

            .filter((item) => item !== "")
            .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    };
    const searchParams = new URLSearchParams(consolidArgs);
    let response = await apiClient.get<
      | {
          success: true;
          result: DocumentDefinitionSchema[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/documentTypes/listAll?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}

async function getAllDocumentTypeOptions(withDetail:boolean=false, withId:boolean=true, onlyEnable:boolean=true) {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: DocumentDefinitionSchema[];
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/documentTypes/getAll?withDetail=${withDetail?1:0}&withId=${withId?1:0}&onlyEnable=${onlyEnable?1:0}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getSubscriptionOptions() {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: SubscriptionSchema[];
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/getMySubscriptions`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteDocumentType(id: string) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/documentTypes/delete`, { _id: id });

    if (response.data.success == true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteDocument(id: string) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/delete`, { _id: id });

    if (response.data.success == true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function editDocumentType(data: {
  _id: string;
  documentTypeName: string;
  enable: boolean;
  customerDefinition: { columnName: string; description: string }[];
  documentDefinition: { columnName: string; description: string }[];
  lineItemsDefinition: { columnName: string; description: string }[];
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/documentTypes/update`, data);

    if ((response.data.success === true, data)) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addDocumentType(data: {
  documentTypeName: string;
  enable: boolean;
  customerDefinition: { columnName: string; description: string }[];
  documentDefinition: { columnName: string; description: string }[];
  lineItemsDefinition: { columnName: string; description: string }[];
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/admin/documentTypes/create`, data);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function saveDocument(data: {
  _id: string;
  CustomerDetail: any;
  DocumentDetail: any;
  LineDetails: any[];
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/save`, data);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function submitForApproval(_id: string) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/submitDocumentForApproval`, { _id: _id });

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function redetectDocument(_id: string, subscription:string) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/redetectDocument`, { _id: _id,subscription });

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addDocument(data: {
  documentType: string;
  documentName: string;
  content: string;
  subscription: string
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/createDocument`, data);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addBulkDocument(data: {
  documentType: string;
  documentName: string;
  content: string[];
  subscription: string
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/createBulkDocument`, data);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addDocuments(data: {
  documentType: string;
  documents: {documentName:string, content:string}[];
  subscription: string
}) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/createDocuments`, data);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
//approveDocument
async function approveDocument(_id: string ) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/approveDocument`, {_id});

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
//rejectDocument
async function rejectDocument(_id: string ) {
  try {
    let response = await apiClient.post<
      | {
          success: true;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/rejectDocument`, {_id});

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function listMyUploadDocument(args: any) {
  try {
    let consolidArgs = {
      offset: String((args.params.current - 1) * args.params.pageSize),
      limit: String(args.take),
      where: args.where
        ? [
            args.where.displayName
              ? `displayName:eq:${args.where.displayName}`
              : "",
            args.where["status"] ? `status:eq:${args.where["status"]}` : "",
          ]

            .filter((item) => item !== "")
            .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    };
    const searchParams = new URLSearchParams(consolidArgs);
    let response = await apiClient.get<
      | {
          success: true;
          result: any[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/listMyUploadDocument?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAllDocumentsDatatable(args: any) {
  try {
    let consolidArgs = {
      offset: String((args.params.current - 1) * args.params.pageSize),
      limit: String(args.take),
      where: args.where
        ? [
            args.where.displayName
              ? `displayName:eq:${args.where.displayName}`
              : "",
            args.where["status"] ? `status:eq:${args.where["status"]}` : "",
          ]

            .filter((item) => item !== "")
            .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    };
    const searchParams = new URLSearchParams(consolidArgs);
    let response = await apiClient.get<
      | {
          success: true;
          result: any[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/listAllDocument?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAllApprovalDocumentDatatable(args: any) {
  try {
    let consolidArgs = {
      offset: String((args.params.current - 1) * args.params.pageSize),
      limit: String(args.take),
      where: args.where
        ? [
            args.where.displayName
              ? `displayName:eq:${args.where.displayName}`
              : "",
            args.where["status"] ? `status:eq:${args.where["status"]}` : "",
          ]

            .filter((item) => item !== "")
            .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    };
    const searchParams = new URLSearchParams(consolidArgs);
    let response = await apiClient.get<
      | {
          success: true;
          result: DocumentDefinitionSchema[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/listAllApproveDocument?${searchParams.toString()}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getDocumentsSummary() {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: any[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/documentSummary`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getMyDocumentsSummary() {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: any[];
          recordTotal: number;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/mydocumentSummary`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getOneDetailDocument(_id: string) {
  try {
    let response = await apiClient.get<
      | {
          success: true;
          result: any | null;
        }
      | {
          success: false;
          error?: {
            msg: string;
            code: string;
          };
        }
    >(`/documents/getOneDetailDocument/${_id}`);

    if (response.data.success === true) {
      const user = response.data;

      return response.data;
    } else {
      return response.data;
    }
  } catch (e) {
    return handleError(e);
  }
}
