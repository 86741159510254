import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";

import {
  CloudUploadOutlined,
  DeleteOutlined,
  FolderViewOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

import { AntDProTable } from "../../components/antdProTable";

import { useForm } from "antd/es/form/Form";
import Swal from "sweetalert2";
import { DocumentDefinitionSchema } from "../../model/documentDefinition";
import { ImportHistory } from "../../model/importHistory";
import { SubscriptionSchema } from "../../model/subscriptionSchema.schema";
import { documentTypeServices } from "../../services/documentService";
import moment from "moment";
//Start
export function ImportHistoryListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [menuActivekey, setMenuActiveKey] = useState<string>("all-files");
  const [filterByStatus, setFilterByStatus] = useState<string>("");

  const [documentTypeOptions, setDocumentTypeOptions] = useState<
    DocumentDefinitionSchema[]
  >([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState<
    SubscriptionSchema[]
  >([]);
  const [summary, setDocumentSummary] = useState<{
    recordTotal: number;
    result: { count: number; _id: string }[];
  }>({ recordTotal: 0, result: [] });
  let tableRef = useRef<ActionType>();

  //Upload Once
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [importForm] = useForm();
  const [uploadFile, setUploadFile] = useState<string>();
  //Combine once
  const [openImportBulkModal, setOpenImportBulkModal] =
    useState<boolean>(false);
  const [importBulkForm] = useForm();
  const [uploadBulkFile, setUploaBulkFile] = useState<string[]>([]);
  //import files
  const [openImportFilesModal, setOpenImportFilesModal] =
    useState<boolean>(false);
  const [importFilesForm] = useForm();
  const [uploadFiles, setUploadFiles] = useState<{documentName:string, content:string}[]>([]);

  useEffect(() => {
    documentTypeServices.getAllDocumentTypeOptions().then((res) => {
      if (res.success == true) {
        setDocumentTypeOptions(res.result);
      }
    });
    documentTypeServices.getSubscriptionOptions().then((res) => {
      if (res.success == true) {
        setSubscriptionOptions(res.result);
      }
    });
    reloadSummary();
  }, []);
  const reloadSummary = () => {
    documentTypeServices.getMyDocumentsSummary().then((res) => {
      if (res.success == true) {
        setDocumentSummary({
          recordTotal: res.recordTotal,
          result: res.result,
        });
      }
    });
  };
  const reload = () => {
    tableRef.current?.reload();
  };
  useEffect(() => {
    reload();
  }, [filterByStatus]);
  const columns: ProColumns<ImportHistory>[] = [
   
    {
      title: t("Document Reference"),
      dataIndex: "_id",
      hideInSearch: false,
      sorter: false,
    },
    {
      title: t("Document Name"),
      dataIndex: "documentName",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Document Type"),
      dataIndex: "documentDefinitionValue",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        if (Array.isArray(text) && text.length > 0) {
          return text[0].documentTypeName;
        } else {
          return "";
        }
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      hideInSearch: true,
      sorter: false,
      renderText: (text) => {
        return t(text);
      },
    },
    {
      title: t("Created Date"),
      dataIndex: "createDate",
      hideInSearch: true,
      defaultSortOrder:"descend",
      sorter: true,
      renderText: (text) => {
        return moment(new Date(text)).format("yyyy-MM-DD HH:mm:ss");
      },
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            <Link to={`/dashboard/documents/${record._id}`}>
              <FolderViewOutlined />
            </Link>

            <Button
              onClick={() => {
                Swal.fire({
                  title: "Do you want to delete the attachment?",
                  showCancelButton: true,
                  confirmButtonText: "Delete",
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    documentTypeServices
                      .deleteDocument(record._id || "")
                      .then((res) => {
                        reload();
                      });
                  }
                });
              }}
              title={t("Delete Document")}
              type="link"
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        <AntDProTable<ImportHistory>
          filterType={"light"}
          size={"small"}
          onRefCallback={(ref: any) => {
            tableRef.current = ref.current;
          }}
          pageSizeOptions={[10, 20, 50, 100, 200]}
          columns={columns}
          rowKey="id"
          scroll={{ x: "max-content" }}
          toolbar={{
            menu: {
              type: "tab",
              activeKey: menuActivekey,
              onChange: (activeKey) => {
                let key = activeKey?.toString() || "";

                switch (key) {
                  case "please-review":
                    setFilterByStatus("OCRCompleted");
                    break;
                  case "awaiting-approval":
                    setFilterByStatus("PendingForApproval");
                    break;
                  case "approved-files":
                    setFilterByStatus("Approved");
                    break;
                  case "rejected-files":
                    setFilterByStatus("Rejected");
                    break;
                  case "all-files":
                    setFilterByStatus("");
                    break;
                }
                setMenuActiveKey(key);
              },
              items: [
                {
                  key: "all-files",
                  label: (
                    <span>
                      {t("All Files")}{" "}
                      <Badge
                        count={summary.recordTotal}
                        showZero
                        color="geekblue"
                      />
                    </span>
                  ),
                },
                {
                  key: "please-review",
                  label: (
                    <span>
                      {t("Please review")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "OCRCompleted")
                            ?.count || 0
                        }
                        showZero
                        color="cyan"
                      />
                    </span>
                  ),
                },
                {
                  key: "awaiting-approval",
                  label: (
                    <span>
                      {t("Awaiting Approval")}{" "}
                      <Badge
                        count={
                          summary.result.find(
                            (p) => p._id == "PendingForApproval"
                          )?.count || 0
                        }
                        showZero
                        color="orange"
                      />
                    </span>
                  ),
                },
                {
                  key: "approved-files",
                  label: (
                    <span>
                      {t("Approved")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "Approved")
                            ?.count || 0
                        }
                        showZero
                        color="lime"
                      />
                    </span>
                  ),
                },
                {
                  key: "rejected-files",
                  label: (
                    <span>
                      {t("Rejected")}{" "}
                      <Badge
                        count={
                          summary.result.find((p) => p._id == "Rejected")
                            ?.count || 0
                        }
                        showZero
                        color="magenta"
                      />
                    </span>
                  ),
                },
              ],
            },
            actions: [
              <Button
                key="primary"
                type="default"
                onClick={() => setOpenImportModal(true)}
              >
                <CloudUploadOutlined /> {t("Import One")}
              </Button>,
              <Button
                key="primary"
                type="default"
                onClick={() => setOpenImportFilesModal(true)}
              >
                <CloudUploadOutlined /> {t("Import Multiples")}
              </Button>,
              <Button
                key="primary"
                type="default"
                onClick={() => setOpenImportBulkModal(true)}
              >
                <CloudUploadOutlined /> {t("Combine")}
              </Button>,
            ],
          }}
          request={(
            params,
            sort,
            filter,
            skip,
            take,
            where,
            sortBy,
            order,
            cb
          ) => {
            if (filterByStatus.length > 0) {
              where.status = filterByStatus;
            }
            setLoading(true);
            documentTypeServices
              .listMyUploadDocument({
                params,
                sort,
                filter,
                skip,
                take,
                where,
                sortBy,
                order,
              })
              .then((res) => {
                setLoading(false);
                if (res.success) {
                  cb({
                    data: res.result,
                    total: res.recordTotal,
                    success: true,
                    page: params.current,
                  });
                } else {
                  cb({
                    data: [],
                    total: 0,
                    success: false,
                    page: params.current,
                  });
                }
              })
              .catch((err) => {
                setLoading(false);
                cb({
                  data: [],
                  total: 0,
                  success: false,
                  page: params.current,
                });
              });
          }}
        />
      </Card>
      <Modal
        okText={t("Save")}
        cancelText={t("Cancel")}
        title={t("Import Purchases")}
        open={openImportModal}
        onOk={() => {
          importForm.submit();
        }}
        onCancel={() => {
          setOpenImportModal(false);
        }}
      >
        <Form
          name="editAccountForm"
          layout="vertical"
          form={importForm}
          onFinish={(props: {
            documentType: string;
            documentName: string;
            subscription: string;
          }) => {
            if (uploadFile) {
              let form = { ...props, content: uploadFile };
              documentTypeServices
                .addDocument(form)
                .then((d) => {
                  if (d.success == true) {
                    setOpenImportModal(false);
                    tableRef.current?.reload();
                  }
                })
                .catch((p) => {});
            }
          }}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="documentName"
                label={t("Document Name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="documentType"
                label={t("Document Type")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={documentTypeOptions.map((p) => {
                    return {
                      label: p.documentTypeName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="subscription"
                label={t("Subscription")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={subscriptionOptions.map((p) => {
                    return {
                      label: p.subscriptionName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="content"
                label={t(
                  "Do not split uploaded files. Each file is a single invoice"
                )}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t("Please upload file"),
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  maxCount={1}
                  showUploadList={true}
                  accept=".jpg, .jpeg, .png, .pdf"
                  beforeUpload={(file, FileList) => {
                    const reader = new FileReader();
                    reader.onload = (e: ProgressEvent<FileReader>) => {
                      setUploadFile(e.target?.result?.toString() || "");
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>{t("Upload")}</div>
                  </button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        okText={t("Save")}
        cancelText={t("Cancel")}
        title={t("Combine images into Purchases")}
        open={openImportBulkModal}
        onOk={() => {
          importBulkForm.submit();
        }}
        onCancel={() => {
          setOpenImportBulkModal(false);
        }}
      >
        <Form
          name="editAccountForm"
          layout="vertical"
          form={importBulkForm}
          onFinish={(props: {
            documentType: string;
            documentName: string;
            subscription: string;
          }) => {
            if (uploadBulkFile) {
              let form = { ...props, content: uploadBulkFile };
              documentTypeServices
                .addBulkDocument(form)
                .then((d) => {
                  if (d.success == true) {
                    setOpenImportBulkModal(false);
                    tableRef.current?.reload();
                  }
                })
                .catch((p) => {});
            }
          }}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="documentName"
                label={t("Document Name")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="documentType"
                label={t("Document Type")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={documentTypeOptions.map((p) => {
                    return {
                      label: p.documentTypeName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="subscription"
                label={t("Subscription")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={subscriptionOptions.map((p) => {
                    return {
                      label: p.subscriptionName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="content"
                label={t(
                  "Do not split uploaded files. Each file is a single invoice"
                )}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t("Please upload file"),
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  multiple={true}
                  showUploadList={true}
                  accept=".jpg, .jpeg, .png"
                  onChange={({ fileList }) => {
                    const updatedFiles = fileList.map((file) => {
                      console.log(file);
                      if (file.originFileObj != undefined) {
                        return new Promise<string>((resolve) => {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            if (e.target?.result) {
                              const base64String = e.target.result.toString();
                              resolve(base64String);
                            }
                          };
                          reader.readAsDataURL(file.originFileObj as File);
                        });
                      }
                      return "";
                    });

                    Promise.all(updatedFiles).then((base64Files: string[]) => {
                      setUploaBulkFile(base64Files.filter((p) => p != ""));
                    });
                  }}
                  beforeUpload={async (file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e.target?.result) {
                        const base64String = e.target?.result?.toString();
                        setUploaBulkFile((prevFiles) => [
                          ...prevFiles,
                          base64String,
                        ]);
                      }
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>{t("Upload")}</div>
                  </button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        okText={t("Save")}
        cancelText={t("Cancel")}
        title={t("Upload multiple Purchases")}
        open={openImportFilesModal}
        onOk={() => {
          importFilesForm.submit();
        }}
        onCancel={() => {
          setOpenImportFilesModal(false);
        }}
      >
        <Form
          name="editAccountForm"
          layout="vertical"
          form={importFilesForm}
          onFinish={(props: {
            documentType: string;
            subscription: string;
          }) => {
            if (uploadFiles) {
              let form = { ...props, documents: uploadFiles };
              documentTypeServices
                .addDocuments(form)
                .then((d) => {
                  if (d.success == true) {
                    setOpenImportFilesModal(false);
                    tableRef.current?.reload();
                  }
                })
                .catch((p) => {});
            }
          }}
        >
          <Row gutter={8}>
            
            <Col span={24}>
              <Form.Item
                name="documentType"
                label={t("Document Type")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={documentTypeOptions.map((p) => {
                    return {
                      label: p.documentTypeName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="subscription"
                label={t("Subscription")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  options={subscriptionOptions.map((p) => {
                    return {
                      label: p.subscriptionName,
                      value: p._id,
                    };
                  })}
                  optionFilterProp="children"
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="content"
                label={t(
                  "Do not split uploaded files. Each file is a single invoice"
                )}
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: t("Please upload file"),
                  },
                ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  multiple={true}
                  showUploadList={true}
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={({ fileList }) => {
                    const updatedFiles = fileList.map((file) => {
                      console.log(file)
                      if (file.originFileObj != undefined) {
                        return new Promise<{documentName:string,content:string}>((resolve) => {
                          const reader = new FileReader();
                          reader.onload = (e) => {
                            if (e.target?.result) {
                              const base64String = e.target.result.toString();
                              resolve({documentName:file.name??"", content:base64String});
                            }
                          };
                          reader.readAsDataURL(file.originFileObj as File);
                        });
                      }
                      return {documentName:"", content:""};
                    });

                    Promise.all(updatedFiles).then((base64Files: {documentName:string,content:string}[]) => {
                      setUploadFiles(base64Files.filter((p) => p!=undefined && p.content != "" ));
                    });
                  }}
                  beforeUpload={async (file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      if (e.target?.result) {
                        const base64String = e.target?.result?.toString();
                        setUploadFiles((prevFiles) => [
                          ...prevFiles,
                          {documentName: file.name ,content:base64String},
                        ]);
                      }
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                  >
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>{t("Upload")}</div>
                  </button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
