import React from 'react';
import { Navigate, Route, HashRouter as Router, Routes } from "react-router-dom";
import './App.css';
import { Button, ConfigProvider, Result } from 'antd';
import i18n from "i18next";
import "./locale/i18n"
import dashboardLayout from './layout/dashboardLayout';
import ForgetPasswordSuccessful from './pages/login/forgetPasswordSuccessful';
import ResetPassword from './pages/login/resetPassword';
import ForgetPassword from './pages/login/forgetPassword';
import { LoginPage } from './pages/login/login';

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" Component={LoginPage}></Route>
          <Route path="/forgetPasswordSuccessful" Component={ForgetPasswordSuccessful}></Route>
          <Route path="/resetPassword" Component={ResetPassword}></Route>
          <Route path="/forgetPassword" Component={ForgetPassword}></Route>
          <Route path="/dashboard/*" Component={dashboardLayout}></Route>
          <Route
            path="*"
            element={ <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
}
            />
        </Routes>
      </Router >
  );
}

export default App;
