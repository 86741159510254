import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface Props {
  base64Data?: string;
}

const Base64Viewer: React.FC<Props> = ({ base64Data }) => {
  const isPDF = base64Data?.startsWith("data:application/pdf");
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pdfError, setPdfError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPDF = async (content:string) => {
      try {
        const pdf = await pdfjs.getDocument({ data: content }).promise;
        setNumPages(pdf.numPages);
        setPdfError(null);
      } catch (error: any) {
        setNumPages(null);
        setPdfError("Error loading PDF: " + (error?.message.toString() ?? ""));
      }
    };
    if (base64Data && base64Data?.startsWith("data:application/pdf")) {
   
      fetchPDF(base64Data);
    }
    
  }, [base64Data]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  if (pdfError) {
    return <div>{pdfError}</div>;
  }
  return (
    <div>
      {base64Data == undefined ? <Empty /> : null}
      {isPDF ? (
        <>
         {pdfError == null ?  <Document
            file={{ data: base64Data }}
            onLoadSuccess={onDocumentLoadSuccess}
            error={<div>Error loading PDF</div>}
            noData={<div>No PDF data provided</div>}
            loading={<div>Loading PDF...</div>}
          >
            {numPages &&
              Array.from(new Array(numPages), (_, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
          </Document>:<embed src={base64Data} type="application/pdf"/>}
        </>
      ) : (
        <img src={base64Data} alt="Base64 Image" />
      )}
    </div>
  );
};

export default Base64Viewer;
